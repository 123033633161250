import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import shapeData from './ShapeData';
import Teacher from '../../../../shared/teacher';
import { TimerBox } from '../Assessment01/TimerBox';

const ShapesContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
`;

const TimerBoxWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 45px;
  z-index: 10;
`;

const ShapeContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || 'auto'};
  left: ${(props) => props.left || 'auto'};
  right: ${(props) => props.right || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  opacity: ${(props) => props.opacity || 1};
  transform: ${(props) => props.transform || 'none'};
  cursor: ${(props) => (props.onClick ? 'url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg) 16 16,auto' : 'default')};
  z-index: ${(props) => props.zIndex || 1};

  /* Styles for mobile phones in portrait mode */
  @media (max-width: 430px) and (orientation: portrait) {
    width: ${(props) => props.mobileWidth};
    height: ${(props) => props.mobileHeight};
    top: ${(props) => props.mobileTop};
    left: ${(props) => props.mobileLeft};
    right: ${(props) => props.mobileRight};
    bottom: ${(props) => props.mobileBottom};
  }

  /* Styles for mobile phones in landscape mode */
  @media (max-width: 675px) and (orientation: landscape) {
    width: ${(props) => props.mobileLandscapeWidth };
    height: ${(props) => props.mobileLandscapeHeight};
    top: ${(props) => props.mobileLandscapeTop };
    left: ${(props) => props.mobileLandscapeLeft };
    right: ${(props) => props.mobileLandscapeRight};
    bottom: ${(props) => props.mobileLandscapeBottom};
    z-index: ${(props) => props.mobileLandscapeZIndex};
  }

  @media (max-width: 932px) and (orientation: landscape) {
    width: ${(props) => props.mobile2LandscapeWidth };
    height: ${(props) => props.mobile2LandscapeHeight};
    top: ${(props) => props.mobile2LandscapeTop };
    left: ${(props) => props.mobile2LandscapeLeft };
    right: ${(props) => props.mobile2LandscapeRight};
    bottom: ${(props) => props.mobile2LandscapeBottom};
    z-index: ${(props) => props.mobile2LandscapeZIndex};
  }
  
  /* iPad in portrait mode (768px) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: ${(props) => props.iPadPortraitWidth || props.width};
    height: ${(props) => props.iPadPortraitHeight || props.height};
    top: ${(props) => props.iPadPortraitTop || props.top};
    left: ${(props) => props.iPadPortraitLeft || props.left};
    right: ${(props) => props.iPadPortraitRight || props.right};
    bottom: ${(props) => props.iPadPortraitBottom || props.bottom};
    z-index: ${(props) => props.iPadPortraitZIndex};
  }
  
  /* iPad in landscape mode (1024px) */
  @media (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
    width: ${(props) => props.iPadLandscapeWidth || props.width};
    height: ${(props) => props.iPadLandscapeHeight || props.height};
    top: ${(props) => props.iPadLandscapeTop || props.top};
    left: ${(props) => props.iPadLandscapeLeft || props.left};
    right: ${(props) => props.iPadLandscapeRight || props.right};
    bottom: ${(props) => props.iPadLandscapeBottom || props.bottom};
    z-index: ${(props) => props.iPadLandscapeZIndex};
  }
  
  /* Styles for small desktops and laptops */
  /* @media (min-width: 1200px) and (max-width: 1439px) {
    width: ${(props) => props.mediumWidth || props.width};
    height: ${(props) => props.mediumHeight || props.height};
    top: ${(props) => props.mediumTop || props.top};
    left: ${(props) => props.mediumLeft || props.left};
    right: ${(props) => props.mediumRight || props.right};
    bottom: ${(props) => props.mediumBottom || props.bottom};
  } */

  /* Styles for large desktops */
  /* @media (min-width: 1440px) and (max-width: 1919px) {
    width: ${(props) => props.largeWidth || props.width};
    height: ${(props) => props.largeHeight || props.height};
    top: ${(props) => props.largeTop || props.top};
    left: ${(props) => props.largeLeft || props.left};
    right: ${(props) => props.largeRight || props.right};
    bottom: ${(props) => props.largeBottom || props.bottom};
  } */

  /* Styles for extra large desktops (2K screens) */
  /* @media (min-width: 1920px) and (max-width: 2559px) {
    width: ${(props) => props.extraLargeWidth || props.width};
    height: ${(props) => props.extraLargeHeight || props.height};
    top: ${(props) => props.extraLargeTop || props.top};
    left: ${(props) => props.extraLargeLeft || props.left};
    right: ${(props) => props.extraLargeRight || props.right};
    bottom: ${(props) => props.extraLargeBottom || props.bottom};
  } */

  @media (min-width: 3440px) {
    /* Styles for ultra wide desktops (3440px and above) */
    width: ${(props) => props.ultraWideWidth || props.width};
    height: ${(props) => props.ultraWideHeight || props.height};
    top: ${(props) => props.ultraWideTop || props.top};
    left: ${(props) => props.ultraWideLeft || props.left};
    right: ${(props) => props.ultraWideRight || props.right};
    bottom: ${(props) => props.ultraWideBottom || props.bottom};

    &:hover img {
      transform: ${(props) => (props.onClick ? 'scale(1.1)' : 'none')};
      transition: transform 0.3s ease;
    }
    
    &:hover div {
      font-size: 10.5em;
      transition: transform 0.3s ease;
    }
  }
`;

const Shape = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.objectFit || 'fill'};
`;

const ShapeText = styled.div`
  position: absolute;
  color: #fff;
  font-weight: bold;
  font-family: 'Aclonica';
  font-size: 2em;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  @media (min-width: 3440px) {
    font-size: 10em;
  }
`;

const TeacherWrapper = styled.div`
      
cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg) 16 16,auto;

    @media (max-width: 430px){ 
      position: relative;
      width:60px;
      height:60px;
      z-index: 10;
      left: 20px;
      top: 20px;
    }
      
    @media (max-width: 550px){ 
      /*left: calc(50% - 100px);
      transform: scale(0.6);*/
      position:relative;
      bottom:0%;
      /* width:100px;
      height:100px; */
    }

    @media (min-width: 550px){ 
       /*  left: 10px; */
        bottom:50%;
        /* width:180px;
        height: 180px; */
    }

    @media (min-width: 750px){ 
      left: 230px;
      position:absolute;
      top: 45px;
      bottom:50%;
      width:100px;
      height: 100px;
      z-index: 20;
    }

    @media (min-width: 3440px) {
      left: 550px;
      top: 90px;
      width: 200px;
      height: 200px;
    }

    @media (max-width: 932px) and (orientation: landscape) {
      left: 35px;
      position:absolute;
      top: 15px;
      bottom:50%;
      width:70px;
      height: 70px;
      z-index: 20;
    }
    
    @media (max-width: 1024px) and (orientation: landscape) {
      left: 30px;
    }
`;

const AssetShapes = ({ onShapeClick, options, playFinished }) => {

  const [updatedShapeData, setUpdatedShapeData] = useState(shapeData);
  const [isPlayFinished, setIsPlayFinished] = useState(playFinished);
  const submitAssessment = () => {
    return "Hello";
  };

  useEffect(() => {
    if (options) {
      const optionsMap = options.reduce((acc, option) => {
        acc[option.id] = option.title;
        return acc;
      }, {});

      const updatedShapes = updatedShapeData.map(shape => {
        if (shape.actionType === 'answer' && shape.onClick in optionsMap) {
          return { ...shape, text: optionsMap[shape.onClick] };
        }
        return shape;
      });

      setUpdatedShapeData(updatedShapes);
    }
  }, [options]);

  useEffect(() => {
    setIsPlayFinished(playFinished);
  }, [playFinished]);

  return (
    <ShapesContainer>
      {updatedShapeData.map((shapeProps) => {
        const { src, actionType, onClick, text } = shapeProps;
        const handleClick = () => {
          
          if (actionType === 'answer' && options) {
            const option = options.find(option => option.id === onClick);
            onShapeClick(option ? option.title : 'Option not available');
          } else if (actionType === 'navigation') {
            onShapeClick(onClick);
          } else {
            console.log('No valid action type');
          }
        };

        return (
          <ShapeContainer
            key={src}
            {...shapeProps}
            onClick={onClick ? handleClick : null}
          >
            <Shape src={src} objectFit={shapeProps.objectFit} />
            {text && <ShapeText>{text}</ShapeText>}
          </ShapeContainer>
        );
      })}
      <TeacherWrapper style={{ backgroundColor: '#e4f2f9', borderRadius: '50%', padding: 5, marginRight: 0, border: '5px solid #087bc7', boxShadow: '3px 3px 3px rgba(100,100,100,0.4)' }}>
        <Teacher
          speaker="main"
          type="default"
          extra={{ glow: false, talk: !isPlayFinished }}
        />
      </TeacherWrapper>
      
      <TimerBoxWrapper>
        <TimerBox onTimeFinished={() => submitAssessment()} darkMode={true} />
      </TimerBoxWrapper>
    </ShapesContainer>
  );
};

export default AssetShapes;