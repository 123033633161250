import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components";
import backgroundImageSVG from "./components/englishassessment01/assets/backgroundsvg.svg";
import MainBoard from "./components/englishassessment01/MainBoard";
//import PopUp from '../../components/PopUp/PopUp';
import Teacher from "../../shared/teacher";
import { TimerBox } from "./components/Assessment01/TimerBox";
import { usePlayAudioSimple } from "../../hooks/usePlayAudioSimple";
import PreloaderView from "../../hocs/PreloaderView";
import SpeakerPointer from "../../shared/SpeakerPointer";
import SmileySVG from "./components/mathassessment01/assets/smiley.svg";
import FrowneySVG from "./components/mathassessment01/assets/frowney.svg";
import Filler from "../lessons/components/filler/Filler";

const EnglishAssessmentContainer = styled.div`
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_cursor.png)
      10 10,
    auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImageSVG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const BoardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin: 30px 0;
  width: 100%;

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const MainBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  order: 1;
`;

const Letter = styled.div`
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_cursor.png)
      10 10,
    auto;
  position: absolute;
  font-size: 4em;
  font-weight: strong;
  color: ${(props) => props.color};
  font-family: "Comic Sans MS", sans-serif;
  left: ${(props) => props.xPos}%;
  top: ${(props) => props.yPos}%;
  &:hover {
    color: #ff6600;
  }

  @media (max-width: 760px) {
    font-size: 3em;
    left: ${(props) => props.mobileXPos}%;
    top: ${(props) => props.mobileYPos}%;
  }
`;

const WordListWrapper = styled.div`
  align-items: center;
  justify-content: space-around;

  margin-left: 25px;
  /* background-color: white; */
  min-height: 400px;
  min-width: 150px;
  max-width: 150px;
  border-radius: 20px;
  padding: 20px;
  order: 2;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    min-height: 150px;
    margin-top: 50px;
    max-width: 600px;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    flex-direction: row;
    min-height: 150px;
    margin-top: 50px;
    width: 100%;
    max-width: 600px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const TeacherWrapper = styled.div`
  @media (max-width: 375px) {
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 10;
    left: 20px;
    top: 20px;
  }
  @media (max-width: 550px) {
    position: relative;
    bottom: 0%;
  }
  @media (min-width: 550px) {
    bottom: 50%;
  }

  @media (max-width: 768px) {
    top: 5px;
    left: 2px;
  }

  @media (min-width: 750px) {
    left: 230px;
    position: absolute;
    top: 45px;
    bottom: 50%;
    width: 100px;
    height: 100px;
    z-index: 20;
  }
  @media (min-width: 3440px) {
    left: 850px;
    top: 90px;
    width: 300px;
    height: 300px;
  }
`;

const colors = [
  "#ff6f61",
  "#6b5b95",
  "#88b04b",
  "#f7cac9",
  "#92a8d1",
  "#955251",
  "#b565a7",
  "#009b77",
  "#dd4124",
  "#45b8ac",
];

const WordItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.bgColor};
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 5px;
`;

const CurrentWordDisplay = styled.div`
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
`;

const ResetButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  &:hover {
    background: #cc0000;
  }
`;

const RedArea = styled.div`
  position: relative;
  width: 410px;
  height: 240px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsContainer = styled.div`
  width: 410px;
  display: flex;
  justify-content: center;
  margin-top: -20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const OptionsLetterBoxWrapper = styled.div`
  display: flex;
  max-width: 80%;
  justify-content: center;
  margin-top: -20px;
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg)
      16 16,
    auto;

  @media (max-width: 768px) {
    margin-top: -0px;
  }
`;

const OptionsLetterBox = styled.div`
  width: 110px;
  height: 80px;
  background-color: ${(props) =>
    props.bgColor === "gray" ? "transparent" : props.bgColor || "transparent"};
  /*border: ${(props) =>
    props.bgColor === "gray" ? "none" : "2px solid #fff"}; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 70px;
  padding: 0px;
  font-weight: 900;
  font-family: "Comic Sans MS", sans-serif;
  border-radius: 10px;
  color: ${(props) => (props.bgColor ? "white" : "black")};
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg)
      16 16,
    auto;

  @media (max-width: 768px) {
    width: ${(props) =>
      props.bgColor === "gray"
        ? "10px"
        : "110px"}; /* Set width to 50px for gray background on mobile */
  }
`;

const ConfirmedWordBox = styled.div`
  width: 250px;
  height: 70px;
  background-color: white;
  color: #000;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
  cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg)
      16 16,
    auto;
`;

const HearAgainButton = styled.button`
  background-color: #4682b4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    background-color: #315f8a;
  }
`;

const EmptyLetterBox = styled.div`
  width: 110px; /* Same width as OptionsLetterBox */
  height: 80px; /* Same height as OptionsLetterBox */
  /*background-color: white;  Matches the background color */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 70px;
  padding: 0px;
  font-weight: 900;
  font-family: "Comic Sans MS", sans-serif;
  /*border-radius: 10px;  Matches the border radius */
  color: transparent; /* Keeps it visually empty */
`;

const TimerAndButtonWrapper = styled.div`
  display: flex;
  align-items: center; /* Vertically centers the items */
  gap: 10px; /* Optional: Adds some space between the timer and button */
  margin-top: 10px; /* Add margin for spacing */
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const PopupButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &.yes {
    background-color: #4caf50;
    color: white;
    &:hover {
      background-color: #45a049;
    }
  }

  &.no {
    background-color: #f44336;
    color: white;
    &:hover {
      background-color: #da190b;
    }
  }
`;

const EnglishAssessment = ({}) => {
  const smallHeader = useMediaQuery("(max-width:600px)");
  const [audio, setAudio] = useState(null);
  const [letters, setLetters] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [currentWord, setCurrentWord] = useState("");
  const [submittedWords, setSubmittedWords] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpContent, setPopUpContent] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [showGrayBoxes, setShowGrayBoxes] = useState(true);
  const [audioFilesToPreload, setAudioFilesToPreload] = useState({});
  const [isPreloaded, setIsPreloaded] = useState(false);
  const [showFiller, setShowFiller] = useState(true);
  const [images, setImages] = useState(null);

  const { playAudio, assignAudio, playArray, playFinished, stopAudio } =
    usePlayAudioSimple();
  const [lessonState, setLessonState] = useState("intro");
  const [fillerSpeakerClicked, setFillerSpeakerClicked] = useState(false);
  const [fillerSpeakerTalking, setFillerSpeakerTalking] = useState(false);
  const [words, setWords] = useState(["on", "at", "in", "an", "if", "am"]);
  const [showReplayPopup, setShowReplayPopup] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [wordSets] = useState([
    ["on", "at", "in", "an", "if", "am"],
    ["on", "at", "in", "an", "if", "am"],
    ["on", "at", "in", "an", "if", "am"],
    ["on", "at", "in", "an", "if", "am"],
    ["at", "in", "on", "if", "am", "an"],
  ]);

  let navigate = useNavigate();

  //const {playArray, playFinished, assignAudio, setPlayFinished, stopAudio, setBackgroundMusicUrl, setIsBackgroundMusicEnabled} = usePlayAudioSimple(data.audio)

  const data = {
    BG: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/conversationMode/Background-01.svg",
    GameController:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/conversationMode/GameController.svg",
    activityMode: "conversation",
    activityName: "Conversations",
    activityTitle: "John and Tobi",
    backgroundMusic:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/mode4_EN_01/mode4_background.mp3",
    convo: [
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_1.mp3",
        speaker: true,
        text: "Hello. My name is John. What is your name?",
        timestamps: [0, 1.14, 1.48, 1.91, 2.2, 3.34, 3.77, 4.07, 4.4],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_2.mp3",
        speaker: false,
        text: "My name is Tobi.",
        timestamps: [0, 0.32, 0.76, 1.06],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_3.mp3",
        speaker: true,
        text: "How old are you Tobi? ",
        timestamps: [0, 0.36, 0.8, 1.12, 1.39],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_4.mp3",
        speaker: false,
        text: "I am six years old. How old are you? ",
        timestamps: [0, 0.21, 0.56, 0.89, 1.25, 1.98, 2.36, 2.72, 3.04],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_5.mp3",
        speaker: true,
        text: "I am seven years old.",
        timestamps: [0, 0.19, 0.52, 0.89, 1.28],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_6.mp3",
        speaker: true,
        text: "Nice to meet you Tobi.?",
        timestamps: [0, 0.47, 0.6, 0.97, 1.2],
      },
      {
        audio:
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_7.mp3",
        speaker: false,
        text: "Nice to meet you John ",
        timestamps: [0, 0.47, 0.6, 0.94, 1.2],
      },
    ],
    cursor:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_cursor.png",
    gameLink: "/game/conversation-game",
    intro: {
      audio:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/blended_sounds_intro.mp3",
      background:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/welcomeBG.svg",
      navForwardBg:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForward.svg",
      navForwardBlueBg:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForwardBlue.svg",
      navForwardGray:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForwardGray.svg",
      placeHolder:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
      rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher.riv",
    },
    outro: {
      audio:
        "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/blended_sounds_outro.mp3",
    },
    riveSpeakers: [
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/john.riv",
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/tobi.riv",
    ],
    selectCursor:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg",
    smallSpeakers: [
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/JohnFace.svg",
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/TobiFace.svg",
    ],
    speakersPlaceHolder: [
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable1.svg",
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg",
    ],
  };

  const defaultAudio = {
    base: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/",
  };
  const correctSound = `${defaultAudio.base}correctAnswer.mp3`;
  const incorrectSound = `${defaultAudio.base}wrongSoundOnly.mp3`;

  const correctImageSrcArray = [SmileySVG, SmileySVG, SmileySVG, SmileySVG];
  const incorrectImageSrcArray = [
    FrowneySVG,
    FrowneySVG,
    FrowneySVG,
    FrowneySVG,
  ];
  const additionalAudios = [
    `${defaultAudio.base}thatsright.mp3`,
    `${defaultAudio.base}fantastic.mp3`,
    `${defaultAudio.base}goodjob.mp3`,
    `${defaultAudio.base}correct.mp3`,
    `${defaultAudio.base}correctAnswer.mp3`,
    `${defaultAudio.base}wrongSoundOnly.mp3`,
  ];

  const handleFillerComplete = () => {
    setShowFiller(false);
  };

  const playResponseSound = (color) => {
    const audioUrl = color === "green" ? correctSound : incorrectSound;
    const audio = new Audio(audioUrl);
    audio.volume = 0.1;
    audio.play().catch((error) => console.error("Error playing audio:", error));
  };

  const allletters = "abcdefghijklmnopqrstuvwxyz";
  const audioMap = {};
  allletters.split("").forEach((letter) => {
    audioMap[
      letter
    ] = `https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/sound_${letter}.mp3`;
  });

  const audioFileURLs = Object.values(audioMap);
  const receivedUniqueAudioFiles = [...new Set(audioFileURLs)];
  console.log("uniqu data are ", receivedUniqueAudioFiles);

  const additionalAudioFiles = [
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/on.mp3",
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/at.mp3",
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/an.mp3",
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/in.mp3",
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/if.mp3",
    "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/am.mp3",
  ];

  const uniqueAudioFiles = [
    ...new Set([
      ...receivedUniqueAudioFiles,
      ...additionalAudioFiles,
      data.intro.audio,
      data.outro.audio,
    ]),
  ];

  console.log("Updated unique data are:", uniqueAudioFiles);
  //setAudioFilesToPreload(uniqueAudioFiles);
  //setIsPreloaded(true);

  const playLetterAudio = (letter, onComplete) => {
    const playLetter = `https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/sound_${letter}.mp3`;
    playAudio(
      playLetter,
      () => {},
      () => {}
    );
    playAudio();

    /* if (isPreloaded) {
        console.log('preloaded data are ', audioFilesToPreload);
        const lowerCaseLetter = letter.toLowerCase();
        //const audioUrl = audioFilesToPreload.find(url => url.includes(`sound_${lowerCaseLetter}.mp3`));

        if (audioUrl) {
          playAudio(audioUrl);
           if (audioUrl) {
            playAudio(audioUrl);
          }
          const audio = new Audio(audioUrl);
          audio.play()
              .then(() => {
                  console.log('Audio is playing successfully.');
                  audio.onended = () => {
                      if (onComplete) {
                          onComplete();
                      }
                  };
              })
              .catch((error) => {
                  console.error('Error playing audio:', error);
              });
        } else {
            console.warn('No valid audio URL provided.');
            if (onComplete) {
                onComplete();
            }
        }
    } else {
        console.warn('Audio not preloaded.');
        if (onComplete) {
            onComplete();
        }
    } */
  };

  const handleReplayYes = () => {
    const nextSetIndex = currentSetIndex + 1;

    if (nextSetIndex < wordSets.length) {
      setCurrentSetIndex(nextSetIndex);
      setSubmittedWords([]);
      setWords(wordSets[nextSetIndex]);
      setSelectedLetters([]);
      setSelectedColors([]);
      setShowReplayPopup(false);
      setShowGrayBoxes(true);

      const result = generateRandomLetters();
      if (result && result.randomWord && result.allLetters) {
        const { randomWord, allLetters } = result;
        setLetters(scatterLetters(allLetters));
        setCurrentWord(randomWord);
        setCurrentLetterIndex(0);
        playLetterAudio(randomWord[0]);
      }
    } else {
      setCurrentSetIndex(0);
      setSubmittedWords([]);
      setWords(wordSets[0]);
      setSelectedLetters([]);
      setSelectedColors([]);
      setShowReplayPopup(false);
      setShowGrayBoxes(true);
      setLessonState("outro");

      setShowFiller(true);
      setFillerSpeakerClicked(false);
      setFillerSpeakerTalking(false);

      setTimeout(() => {
        stopPlaying();
        setFillerSpeakerClicked(true);
        playAudio(
          data.outro.audio,
          () => {
            setFillerSpeakerTalking(true);
          },
          () => {
            setFillerSpeakerTalking(false);
          }
        );
      }, 500);
    }
  };

  /* const handleReplayNo = () => {
    playAudio(data.outro.audio, () => {}, () => {
      navigate('/');
    });
  }; */
  const handleReplayNo = () => {
    setSubmittedWords([]);
    setWords(["on", "at", "in", "an", "if", "am"]);
    setSelectedLetters([]);
    setSelectedColors([]);
    setShowReplayPopup(false);
    setShowGrayBoxes(true);
    setLessonState("outro");

    const result = generateRandomLetters();
    if (result && result.randomWord && result.allLetters) {
      const { randomWord, allLetters } = result;
      setLetters(scatterLetters(allLetters));
      setCurrentWord(randomWord);
      setCurrentLetterIndex(0);

      setShowFiller(true);
      setFillerSpeakerClicked(false);
      setFillerSpeakerTalking(false);

      setTimeout(() => {
        stopPlaying();
        setFillerSpeakerClicked(true);
        playAudio(
          data.outro.audio,
          () => {
            setFillerSpeakerTalking(true);
          },
          () => {
            setFillerSpeakerTalking(false);
          }
        );
      }, 500);
      //fillerSpeakerClickedHandler(lessonState);
    }
  };

  const checkLetterCorrectness = (letter, index) => {
    const isCorrect = currentWord[index] === letter;
    const color = isCorrect ? "green" : "red";

    playResponseSound(color);

    return color;
  };

  const isWordSubmitted = (word) => {
    return submittedWords.some((submittedWord) => submittedWord.word === word);
  };

  const generateRandomLetters = () => {
    const availableWords = words.filter((word) => !isWordSubmitted(word));

    if (availableWords.length === 0) {
      //alert('Game Over');
      return null;
    }

    let randomWord;
    do {
      randomWord =
        availableWords[Math.floor(Math.random() * availableWords.length)];
    } while (isWordSubmitted(randomWord));

    setWords((prevWords) => prevWords.filter((word) => word !== randomWord));

    console.log(
      "Remaining words:",
      availableWords.filter((word) => word !== randomWord)
    );

    const lettersArray = randomWord.split("");
    const extraLetters = "abcdefghijklmnopqrstuvwxyz"
      .split("")
      .filter((letter) => !lettersArray.includes(letter))
      .sort(() => 0.5 - Math.random())
      .slice(0, 4);

    const allLetters = [...lettersArray, ...extraLetters];
    return { randomWord, allLetters };
  };

  useEffect(() => {
    //const { randomWord, allLetters } = generateRandomLetters();
    //const scatteredLetters = scatterLetters(allLetters);
    //setLetters(scatteredLetters);
    //setCurrentWord(randomWord);

    console.log(
      "Current word list:",
      submittedWords.map((wordObj) => wordObj.word)
    );

    if (isPreloaded) {
      //playLetterAudio(randomWord[0]);
      //replayWord()
      setWords(wordSets[0]);
      const { randomWord, allLetters } = generateRandomLetters();
      const scatteredLetters = scatterLetters(allLetters);
      setLetters(scatteredLetters);
      setCurrentWord(randomWord);
    }
  }, [isPreloaded]);

  const fixedPositions = [
    { xPos: 12, yPos: 15, mobileXPos: 25, mobileYPos: 11 },
    { xPos: 45, yPos: 0, mobileXPos: 45, mobileYPos: 10 },
    { xPos: 75, yPos: -3, mobileXPos: 75, mobileYPos: 15 },
    { xPos: 25, yPos: 40, mobileXPos: 18, mobileYPos: 40 },
    { xPos: 45, yPos: 45, mobileXPos: 42, mobileYPos: 45 },
    { xPos: 72, yPos: 40, mobileXPos: 69, mobileYPos: 40 },
  ];

  const scatterLetters = (lettersArray) => {
    return lettersArray.map((letter, index) => {
      const { xPos, yPos, mobileXPos, mobileYPos } = fixedPositions[index];
      return {
        letter,
        xPos,
        yPos,
        mobileXPos,
        mobileYPos,
        color: getRandomColor(),
      };
    });
  };

  const getRandomColor = () => {
    const colors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#FF69B4"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const speakSequence = (
    firstLetter,
    secondLetter,
    combination,
    repetitions,
    onComplete
  ) => {
    let count = 0;

    /* const getAudioUrl = (letter) => 
      `https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/sound_${letter}.mp3`; */

    const getAudioUrl = (letter, isCombination = false) =>
      isCombination
        ? `https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/${letter}.mp3`
        : `https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/sound_${letter}.mp3`;

    const speakCycle = () => {
      if (count < repetitions) {
        playAudio(
          getAudioUrl(firstLetter),
          () => {},
          () => {
            playAudio(
              getAudioUrl(secondLetter),
              () => {},
              () => {
                playAudio(
                  getAudioUrl(combination, true),
                  () => {},
                  () => {
                    count++;
                    if (count < repetitions) {
                      setTimeout(speakCycle, 500);
                    } else if (onComplete) {
                      onComplete();
                    }
                  }
                );
                /* playAudio(getAudioUrl(firstLetter), 
                  () => {
                  },
                  () => {
                    playAudio(getAudioUrl(secondLetter), () => {}, ()=>{
                      count++;
                      if (count < repetitions) {
                        setTimeout(speakCycle, 500);
                      } else {
                        if (onComplete) {
                          onComplete();
                        }
                      }
                    })
                  }
                ); */
              }
            );
          }
        );
      }
    };

    speakCycle();
  };

  const handleLetterClick = (letter) => {
    setLetters((prev) => prev.filter((item) => item.letter !== letter));

    if (selectedLetters.length < currentWord.length) {
      const newSelectedLetters = [...selectedLetters, letter];
      setSelectedLetters(newSelectedLetters);

      const newColor = checkLetterCorrectness(
        letter,
        newSelectedLetters.length - 1
      );
      setSelectedColors([...selectedColors, newColor]);

      if (isPreloaded) {
        //playLetterAudio(letter);
      }

      if (newSelectedLetters.length === 2) {
        const isBothCorrect =
          checkLetterCorrectness(newSelectedLetters[0], 0) === "green" &&
          checkLetterCorrectness(newSelectedLetters[1], 1) === "green";

        if (isBothCorrect) {
          const firstLetter = newSelectedLetters[0];
          const secondLetter = newSelectedLetters[1];
          const combination = firstLetter + secondLetter;

          speakSequence(firstLetter, secondLetter, combination, 3, () => {
            setShowGrayBoxes(false);
            setTimeout(() => {
              const formedWord = combination;
              if (formedWord === currentWord) {
                setSubmittedWords((prevWords) => [
                  ...prevWords,
                  { word: formedWord, color: getRandomColor() },
                ]);
                setPopUpContent("Correct! Word submitted.");
              } else {
                setPopUpContent("Incorrect! Try again.");
              }
              //setShowPopUp(true);
              if (words.length <= 1) {
                setShowReplayPopup(true);
              } else {
                const result = generateRandomLetters();
                if (result && result.randomWord && result.allLetters) {
                  const { randomWord, allLetters } = result;
                  setLetters(scatterLetters(allLetters));
                  setCurrentWord(randomWord);
                  setCurrentLetterIndex(0);

                  playLetterAudio(randomWord[0]);

                  setShowGrayBoxes(true);
                  setSelectedLetters([]);
                  setSelectedColors([]);
                } else {
                  setShowGrayBoxes(true);
                  setSelectedLetters([]);
                  setSelectedColors([]);
                  //playAudio(data.outro.audio, () => {}, () => {});
                }
              }
            }, 2000);
          });
        }
      }

      if (currentWord[newSelectedLetters.length - 1] === letter) {
        setCurrentLetterIndex(newSelectedLetters.length);

        if (newSelectedLetters.length < currentWord.length) {
          playLetterAudio(currentWord[newSelectedLetters.length]);
        }
      }
    }
  };

  const handleOptionBoxClick = (index) => {
    const letter = selectedLetters[index];
    const newSelectedLetters = selectedLetters.filter((_, i) => i !== index);
    const newSelectedColors = selectedColors.filter((_, i) => i !== index);
    setSelectedLetters(newSelectedLetters);
    setSelectedColors(newSelectedColors);

    setLetters((prev) => {
      const letterDetails = fixedPositions.find(
        (_, i) => currentWord[i] === letter
      );
      return [
        ...prev,
        {
          letter,
          ...letterDetails,
          color: getRandomColor(),
        },
      ];
    });
  };
  /* const handleOptionBoxClick = (index) => {
    setSelectedLetters((prevSelectedLetters) => {
      const removedLetter = prevSelectedLetters[index];
      setLetters((prevLetters) => [...prevLetters, removedLetter]);
      return prevSelectedLetters.filter((_, i) => i !== index);
    });
  
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.filter((_, i) => i !== index)
    );
  }; */

  const handleReset = () => {
    setSelectedLetters([]);
    setSelectedColors([]);
    setLetters(scatterLetters(generateRandomLetters()));
  };

  /* const submitWord = () => {
    const formedWord = selectedLetters.join('');
    if (formedWord === currentWord) {
      setSubmittedWords((prevWords) => [...prevWords, { word: formedWord, color: getRandomColor() }]);
      setPopUpContent('Correct! Word submitted.');
    } else {
      setPopUpContent('Incorrect! Try again.');
    }
    setShowPopUp(true);
    
    const { randomWord, allLetters } = generateRandomLetters();
    setLetters(scatterLetters(allLetters));
    setCurrentWord(randomWord);

    playLetterAudio(randomWord[0]);
    setShowGrayBoxes(true);
    
    setSelectedLetters([]);
    setSelectedColors([]);

  }; */

  const closePopUp = () => {
    setShowPopUp(false);
  };

  const replayWord = () => {
    setIsFirstClick(false);
    if (currentLetterIndex < currentWord.length) {
      playLetterAudio(currentWord[currentLetterIndex]);
    }
  };

  const handlePreloaderLoad = (newloadedAudio) => {
    console.log("loaded audio is ", newloadedAudio);
    assignAudio(newloadedAudio);
    setAudio(newloadedAudio);
    setAudioFilesToPreload(newloadedAudio);
    setIsPreloaded(true);
  };

  const renderContent = () => (
    <EnglishAssessmentContainer>
      <TimerAndButtonWrapper>
        <TeacherWrapper
          style={{
            backgroundColor: "#e4f2f9",
            borderRadius: "50%",
            padding: 5,
            marginRight: 0,
            border: "5px solid #087bc7",
            boxShadow: "3px 3px 3px rgba(100,100,100,0.4)",
          }}
        >
          <SpeakerPointer
            cursor={
              "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg"
            }
            isTalking={isFirstClick ? false : !playFinished}
            handleClick={replayWord}
            speaker={
              "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg"
            }
            riveSpeaker={
              "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv"
            }
            size={"fullSingle"}
            showPointer={isFirstClick}
          />
        </TeacherWrapper>
      </TimerAndButtonWrapper>
      <BoardWrapper>
        <MainBoardWrapper>
          <MainBoard>
            <RedArea>
              {letters.map(
                (
                  { letter, xPos, yPos, mobileXPos, mobileYPos, color },
                  index
                ) => (
                  <Letter
                    key={`${letter}-${index}`}
                    onClick={() => handleLetterClick(letter)}
                    color={color}
                    xPos={xPos}
                    yPos={yPos}
                    mobileXPos={mobileXPos}
                    mobileYPos={mobileYPos}
                  >
                    {letter}
                  </Letter>
                )
              )}
            </RedArea>
          </MainBoard>
          <OptionsContainer>
            <OptionsLetterBoxWrapper>
              {Array(currentWord.length)
                .fill(null)
                .map((_, index) => (
                  <React.Fragment key={index}>
                    {selectedLetters[index] ? (
                      <OptionsLetterBox
                        onClick={() => handleOptionBoxClick(index)}
                        bgColor={selectedColors[index]}
                      >
                        {selectedLetters[index]}
                      </OptionsLetterBox>
                    ) : (
                      <EmptyLetterBox />
                    )}
                    {index === 0 && showGrayBoxes && (
                      <>
                        <OptionsLetterBox bgColor="gray" />
                        <OptionsLetterBox bgColor="gray" />
                      </>
                    )}
                  </React.Fragment>
                ))}
            </OptionsLetterBoxWrapper>
          </OptionsContainer>
        </MainBoardWrapper>
        <WordListWrapper>
          {submittedWords.map(({ word, color }, index) => (
            <WordItem key={`${word}-${index}`} bgColor={color}>
              {word}
            </WordItem>
          ))}
        </WordListWrapper>
      </BoardWrapper>
      {showReplayPopup && (
        <PopupOverlay>
          <PopupContent>
            <h2>Would you like to play again?</h2>
            <div style={{ marginTop: "20px" }}>
              <PopupButton className="yes" onClick={handleReplayYes}>
                Yes
              </PopupButton>
              <PopupButton className="no" onClick={handleReplayNo}>
                No
              </PopupButton>
            </div>
          </PopupContent>
        </PopupOverlay>
      )}
    </EnglishAssessmentContainer>
  );

  const stopPlaying = () => {
    stopAudio();
  };

  const fillerSpeakerClickedHandler = (state) => {
    stopPlaying();
    setFillerSpeakerClicked(true);
    playAudio(
      data[lessonState].audio,
      () => {
        setFillerSpeakerTalking(true);
      },
      () => {
        setFillerSpeakerTalking(false);
      }
    );
  };

  const fillerNavClickedHandler = (state) => {
    stopPlaying();
    if (state === "intro" || state === "outro") {
      setShowFiller(false);

      if (state === "outro") {
        navigate("/lessons");
      }
    }
  };

  return (
    //<PreloaderView audios={ audioFilesToPreload?.length > 0 ? audioFilesToPreload : ['https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/sound_a.mp3']} onLoad={handlePreloaderLoad}>
    <PreloaderView
      audios={uniqueAudioFiles}
      onLoad={handlePreloaderLoad}
      images={data.images}
      loadImages={(imgs) => setImages(imgs)}
    >
      {isPreloaded ? (
        showFiller ? (
          <Filler
            speakerClicked={() => fillerSpeakerClickedHandler(lessonState)}
            smallHeader={smallHeader}
            forwardClicked={() => fillerNavClickedHandler(lessonState)}
            payload={{
              ...data.intro,
              cursor: data.cursor,
              pointer: data.selectCursor,
            }}
            talking={fillerSpeakerTalking}
            started={fillerSpeakerClicked}
            navBg={data.intro.navForwardGray}
            navBg1={data.intro.navForwardBg}
            navBg2={data.intro.navForwardBlueBg}
            navPointer={data.selectCursor}
            showPointerStatus={lessonState === "outro" ? false : true}
            /* images={images} */
          />
        ) : (
          renderContent()
        )
      ) : (
        <div>Loading...</div>
      )}
    </PreloaderView>
  );
};

export default EnglishAssessment;
