import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextBG from "../svg-resources/textHolder.svg";

const TypewriterText = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, 50); // Adjust this value to change typing speed

      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [index, text, onComplete]);

  useEffect(() => {
    setDisplayText("");
    setIndex(0);
  }, [text]);

  return (
    <StyledText>
      {displayText}
      {/*<Cursor />*/}
    </StyledText>
  );
};

const StyledText = styled.div`
  font-size: 2.6rem;
  min-height: 3.7em;
  white-space: pre-wrap; // Preserves whitespace and allows wrapping
  text-align: center;
  font-family: "Comic Sans MS";
  width: 45vw;
  font-weight: bold;
  color: #714f3f;
  margin-top: 12vh;
  margin-left: 50%;
  transform: translateX(-50%);
  background-image: url("/images/textHolder.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 4%;
  padding-right: 4%;

  // iPad Mini and normal iPad (Portrait and Landscape)
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 70vw;
    font-size: 2.2rem;
    min-height: 3.2em;
    margin-top: 8vh;
  }

  // iPad Mini specific (Portrait)
  @media screen and (min-width: 768px) and (max-width: 834px) and (orientation: portrait) {
    width: 80vw;
    font-size: 2rem;
    min-height: 3em;
  }

  @media screen and (min-width: 1500px) {
    height: 4.2em;
  }

  @media screen and (min-width: 1800px) {
    width: 26%;
  }

  @media screen and (max-width: 750px) {
    margin-top: 10vh;
    width: 100%;
    min-height: 3em;
    font-size: 2rem;
  }
`;

const Cursor = styled.span`
  display: inline-block;
  width: 2px;
  height: 2em;
  background-color: #ffffff;
  margin-left: 2px;
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default TypewriterText;
