import {
  BackgroundContainer,
  TalkerContainer,
  NavContainer,
  TalkerDiv,
} from "./Filler.styles";
import SpeakerPointer from "../../../../shared/SpeakerPointer";
import { useState } from "react";
import ForwardNav from "../../../../shared/forwardNav/ForwardNav";
import { useEffect } from "react";
export default function Filler({
  speakerClicked,
  forwardClicked,
  smallHeader,
  payload,
  talking,
  started,
  navBg,
  navBg1,
  navBg2,
  navPointer,
  fillerShowed,
  showPointerStatus = true,
  images,
  riveSpeaker ="https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher.riv",
  speaker ="https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
}) {
  useEffect(() => {
    fillerShowed && fillerShowed();
  }, []);
  const [showPointer, setShowPointer] = useState(showPointerStatus);

  const handleSpeakerClick = () => {
    // always stop showing the pointer
    setShowPointer(false);
    // play the audio for the
    speakerClicked();
  };
  return (
    <BackgroundContainer
      smallHeader={smallHeader}
      background={payload.background}
      cursor={payload.cursor}
    >
      <TalkerDiv>
        <TalkerContainer>
          <SpeakerPointer
            cursor={payload.pointer}
            isTalking={talking}
            handleClick={handleSpeakerClick}
            riveSpeaker={riveSpeaker}
            speaker={speaker}
            size={"fullSingle"}
            showPointer={showPointer}
          />
        </TalkerContainer>
      </TalkerDiv>

      <NavContainer>
        <ForwardNav
          images={images}
          bg={navBg}
          bg1={navBg1}
          bg2={navBg2}
          canClick={started}
          handleClick={forwardClicked}
          pointer={navPointer}
        />
      </NavContainer>
    </BackgroundContainer>
  );
}
