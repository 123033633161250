import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

const GridButton = styled.div`
  width: 8vw;
  height: 8vw;
  background: linear-gradient(180deg, #e11c6b 50%, #bd175a 50%);
  ${centerContent};
  border-radius: 50%;
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : props.selectCursor
      ? `url(${props.selectCursor}) 16 16, auto`
      : "inherit"};
  z-index: 2;
  transition: 0.2s all;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  @media screen and (max-width: 720px) {
    width: 40px;
    height: 40px;
  }
  div {
    background-color: #fff;
    width: 50%;
    aspect-ratio: 1/1;
  }
`;

export const NextGrid = styled(GridButton)`
  div {
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    margin-left: 10%;
  }
`;
export const PrevGrid = styled(GridButton)`
  div {
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
    margin-right: 10%;
  }
`;
