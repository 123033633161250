import React, {
  useEffect,
  useReducer,
  useCallback,
  useState,
  useRef,
} from "react";
import { Spring, animated, useSpringRef, useSpring } from "react-spring";
import "./gameLayout.css";
import defaultbackground from "../../assets/images/background-02.svg";
import PlayButton from "./components/PlayButton";
import { Howl } from "howler";
import Chime001 from "./audio/chime001.wav";
import Chime002 from "./audio/chime002.wav";
import GameOverImg from "./images/game-over.svg";
import ScoreBoard from "./components/ScoreBoard";
import PreloaderView from "../../hocs/PreloaderView";
import styled, { keyframes } from "styled-components";
import Teacher from "../../shared/teacher";
import Sparkles from "../../components/Effects/Sparkles";
import Confetti from "../../components/Effects/Confetti";
import CorrectImg from "./images/correct-img.png";
import WrongImg from "./images/wrong-img.png";
import { v4 as uuidv4 } from "uuid";
import WritingBox from "./components/WritingBox";
import { saveUsageProgressData } from "../../services/usage_progress.services";

const OptionPopup = styled.div`
  min-height: 50px;
  min-width: 300px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: inset 5px 5px 0 -1px #e4e4e3, inset -5px -5px 0 -1px #9c8d87,
    0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%),
    0 5px 5px rgb(0 0 0 / 20%);
  background-color: rgb(253 253 253);
  flex-direction: column;
  padding: 10px;
`;

const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-width: 150px;
  padding: 10px;
`;

const PopupContentWrapper = styled.div`
  padding: 10px;
  background: #eeeeee;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
`;

const OptionImage = React.forwardRef((props, ref) => {
  return (
    <>
      {props.src && (
        <img
          ref={ref}
          style={{ width: props.width || "100%" }}
          src={props.src}
        />
      )}
    </>
  );
});

// Holds the floating opion image.
const OptionTextBox = React.forwardRef((props, ref) => {
  //const {ref1, ref2} = ref;

  return props.optionText && props.optionText !== "" ? (
    <div className={"floating-container-text"}>
      {props.showSparkles ? (
        <Sparkles color={"#ffd53c"}>
          <span ref={ref}>{props.optionText}</span>
        </Sparkles>
      ) : (
        <span ref={ref}>{props.optionText}</span>
      )}
    </div>
  ) : null;
});

// Floating option animation container.
const FloatingContainer = React.memo(
  (props) => {
    const ref = React.createRef();
    const containerRef = React.createRef();
    const imageRef = React.createRef();

    const textContainerref = React.createRef();
    const imageContainerRef = React.createRef();

    const popupRef = useRef(null);
    const animationContainerRef = useSpringRef();

    const containerOptions = useRef([...props.optionsData]);
    const activeOptionData = useRef(props.optionsData[0]);
    const currentOptionsIndex = useRef(0);
    const selectedOptions = useRef([]);

    const optionAudioPlayer = useRef(null);

    const optionClicked = useRef(false);

    const hasTrackStarted = useRef(false);

    const animationLoopCounter = useRef(0);

    function resetContainer() {
      console.log("HHee");
      let currentIndex = currentOptionsIndex.current;

      // Pauses the animation after all the options are
      // exhausted.

      if (
        currentOptionsIndex.current + 1 >= containerOptions.current.length &&
        !optionClicked.current
      ) {
        //setIsPaused(true);
        api.pause();

        console.log("ended");

        if (containerRef && containerRef.current)
          containerRef.current.style.opacity = 0;

        props.trackOptionsCompleted && props.trackOptionsCompleted(props.id);
      }

      if (currentOptionsIndex.current + 1 < containerOptions.current.length) {
        currentOptionsIndex.current = currentIndex + 1;
        activeOptionData.current = containerOptions.current[currentIndex + 1];
        ////////////////////
        /*}
       else
       {
        currentOptionsIndex.current = 0;
        activeOptionData.current = containerOptions.current[0];
       }
        
       {*/
        ///////////////////////
        if (containerRef && containerRef.current)
          containerRef.current.style.opacity = 1;

        if (ref && ref.current)
          ref.current.innerHTML = activeOptionData.current
            ? activeOptionData.current.optionText
            : "";

        if (imageRef && imageRef.current)
          imageRef.current.src = activeOptionData.current
            ? activeOptionData.current.optionImage
            : null;

        if (textContainerref && textContainerref.current)
          textContainerref.current.innerHTML = activeOptionData.current
            ? activeOptionData.current.optionText
            : "";

        if (imageContainerRef && imageContainerRef.current)
          imageContainerRef.current.src = activeOptionData.current
            ? activeOptionData.current.optionImage
            : null;

        //api && api.set({delay:0})
        //console.log("reset",  {styles});
      }
    }

    useEffect(() => {
      console.log("isset", [...props.optionsData]);

      let reelOptions = [];
      // repead the sequence to give the effect of a continous reel of options
      for (
        let j = 0;
        j < 3;
        j++ // shuffledOptionsPool.length; i++)
      ) {
        reelOptions = [...reelOptions, ...props.optionsData];
      }

      containerOptions.current = [...reelOptions];
      activeOptionData.current = props.optionsData[0];
      currentOptionsIndex.current = 0;
      optionClicked.current = false;
      optionAudioPlayer.current = null;

      // console.log("imageRef",  activeOptionData.current.optionImage);

      if (ref && ref.current)
        ref.current.innerHTML = activeOptionData.current
          ? activeOptionData.current.optionText
          : "";

      if (imageRef && imageRef.current)
        imageRef.current.src = activeOptionData.current
          ? activeOptionData.current.optionImage
          : null;

      if (textContainerref && textContainerref.current)
        textContainerref.current.innerHTML = activeOptionData.current
          ? activeOptionData.current.optionText
          : "";

      if (imageContainerRef && imageContainerRef.current)
        imageContainerRef.current.src = activeOptionData.current
          ? activeOptionData.current.optionImage
          : null;
    }, [props.optionsData]);

    /*const rightSelectionSound = new Howl({
        src: Chime001
      });

    const wrongSelectionSound = new Howl({
        src: Chime002
      });*/

    const handleOptionPopupClick = (opt) => {
      popupRef.current.style.display = "none";

      // Clears the audio loop
      if (optionAudioPlayer.current) {
        clearInterval(optionAudioPlayer.current);
      }

      animationContainerRef && animationContainerRef.resume();

      api.resume();
    };

    // Plays the current option's audio
    const playOptionAudio = (audioKey) => {
      if (props.audios && props.audios[audioKey]) {
        props.talkAction && props.talkAction(props.audios[audioKey]);
      }
    };

    // Handles the 'yes' option if selected on the
    // dialog.
    const handleOptionClick = () => {
      optionClicked.current = true;
      // Checks if the current option has already been clicked
      if (
        selectedOptions.current.some(
          (e) => e.id === activeOptionData.current.id
        )
      ) {
        return;
      }

      // Default game mode feedback audio.
      /*if(activeOptionData.current.optionPoints > 0)
            rightSelectionSound.play();
        else
            wrongSelectionSound.play();*/

      // Clears the audio loop
      if (optionAudioPlayer.current) {
        clearInterval(optionAudioPlayer.current);
      }

      selectedOptions.current.push(activeOptionData.current);

      props.clickHandler && props.clickHandler(activeOptionData.current);
      // Marks the selected option by fading it.
      containerRef.current.style.opacity = 0.2;

      popupRef.current.style.display = "none";
      //setIsPaused(true);

      //setIsOptionPopupOpen(false);
      //setIsPaused(false);
      api.resume();
    };

    const [styles, api] = useSpring(() => ({
      config: { duration: 1000 }, //props.optionDisplayDuration},
      loop: true,
      //delay:2000,
      //delay:props.index*5000,//props.delay || props.index*(props.optionDisplayDuration/2),
      from: { bottom: "15%", scale: 0 },
      /*to: { bottom: '85%',scale:1  },*/
      to: [
        { bottom: "16%", scale: 1 },
        { bottom: "20%", scale: 1 },
        { bottom: "30%", scale: 1 },
        { bottom: "40%", scale: 1 },
        { bottom: "50%", scale: 1 },
        { bottom: "60%", scale: 1 },
        { bottom: "70%", scale: 1 },
        { bottom: "80%", scale: 1 },
        { bottom: "85%", scale: 1 },
        { bottom: "85%", scale: 0 },
      ],
      onStart: () => {
        //console.log("start");
        if (!hasTrackStarted.current) {
          api.pause();
          setTimeout(() => deplayStart(), 5000 * props.index);
          hasTrackStarted.current = true;
        }

        animationLoopCounter.current = animationLoopCounter.current + 1;

        if (animationLoopCounter.current >= 22) {
          resetContainer();
          animationLoopCounter.current = 0;
        }

        if (props.index === 1)
          console.log(
            "loop counter" + props.index,
            animationLoopCounter.current
          );

        if (
          animationLoopCounter.current >= 17 &&
          currentOptionsIndex.current + 1 >= containerOptions.current.length &&
          !optionClicked.current
        ) {
          if (containerRef && containerRef.current)
            containerRef.current.style.opacity = 0;
        }
      },
      onRest: () => console.log("onRest"),
      // onRest:()=>resetContainer(),
      //onDelayEnd:()=>console.log("onDelayEnd"),
      //onProps:()=>console.log("onProps")
    }));

    function deplayStart() {
      api.resume();
    }

    console.log("RERENDERED", {
      id: props.id,
      options: props.optionsData,
      delay: props.delay || props.index * 1000,
    });

    useEffect(() => {
      if (props.setAnimationHandles) {
        props.setAnimationHandles((anh) => {
          return { ...anh, [props.id]: api };
        });
      }
    }, []);

    useEffect(() => {
      // Clear any audio that is still pending
      // when this component unmounts.
      return () => {
        // Clears the audio loop
        if (optionAudioPlayer.current) {
          clearInterval(optionAudioPlayer.current);
        }
      };
    });

    /*useEffect(()=>{
        console.error("IM", activeOptionData.current.optionImage)
    },[activeOptionData])*/

    return (
      <>
        <div
          ref={containerRef}
          style={{
            minHeight: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${(1 * 100) / (2 * props.totalNumberOfTracks)}%`,
            position: "relative",
            backgroundColor: "rgba(10,10,10,0.05)",
          }}
        >
          {/*<Spring ref={animationContainerRef} loop pause={isPaused} config={{duration:props.optionDisplayDuration}} delay={props.delay || 0} from={{bottom:'-10%'}}  to={{ bottom: '120%' }} onStart={()=>console.log("start")} onRest={()=>resetContainer()}>
    {styleProp => (*/}
          <animated.div
            onClick={handleOptionClick}
            className={"floating-container"}
            style={{
              bottom: styles.bottom,
              transform: styles.scale.to((v) => `scale(${v})`),
            }}
          >
            <OptionImage
              ref={imageRef}
              src={activeOptionData.current.optionImage}
            />
            <OptionTextBox
              ref={ref}
              optionText={activeOptionData.current.optionText}
            />
          </animated.div>
          {/*)}
    </Spring>*/}
        </div>

        {/*Option Popup */}
        {
          <div
            ref={popupRef}
            style={{
              height: "100vh",
              zIndex: 30,
              width: "100%",
              display: "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              position: "absolute",
              backgroundColor: "rgba(10,10,10,0.05)",
            }}
          >
            <OptionPopup>
              <PopupContentWrapper>
                <OptionImage
                  ref={imageContainerRef}
                  src={activeOptionData.current.optionImage}
                />
                <OptionTextBox
                  ref={textContainerref}
                  optionText={activeOptionData.current.optionText}
                />
              </PopupContentWrapper>
              <PopupButtonsContainer>
                <div
                  className={"game-primary-button"}
                  onClick={handleOptionClick}
                >
                  Yes
                </div>
                <div
                  className={"game-secondary-button"}
                  onClick={() => handleOptionPopupClick()}
                >
                  No
                </div>
              </PopupButtonsContainer>
            </OptionPopup>
          </div>
        }
      </>
    );
  },
  (pre, nex) => pre.id === nex.id
);

/************************************* ******/
// Mode : Game 01
//************************************ ******/
// Props :
//-----------------------------------------
// gameBackground : image background
// gameTime : game duration in seconds
// optionDisplayDuration: the duration of the option
//                          in seconds
// gameOptions : the options presented to
//               the player.
// onGameEnd : handler for game end event
// onGameStart : hander for game start event
// ******************************************
const Game11 = (props) => {
  const timerIdRef = useRef(null);

  const initialActivityTime = props.gameTime || 30; //seconds

  const [isPlaying, setIsPlaying] = useState(false);
  const [gamePoints, setGamePoints] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGamePaused, setIsGamePaused] = useState(false);

  const [currentGameOption, setCurrentGameOption] = useState(null);

  const [trackOptions, setTrackOptions] = useState([]);

  const [audios, setAudios] = useState({});
  const [animationHandles, setAnimationHandles] = useState({});
  const [isInstructorTalking, setIsInstructorTalking] = useState(false);
  const [showPositiveFeedback, setShowPositiveFeedback] = useState(false);
  const [showNegativeFeedback, setShowNegativeFeedback] = useState(false);

  const [canPlayGame, setCanPlayGame] = useState(false);

  const [activeTeacherAudio, setActiveTeacherAudio] = useState(null);

  const [numberOfTracks, setNumberOfTracks] = useState(
    props.numberOfTracks ? props.numberOfTracks : 2
  );

  const [numberOfQuestions, setNumberOfQuestions] = useState(
    props.numberOfQuestions ? props.numberOfQuestions : 20
  );

  const [numberOfOptionsPerQuestion, setNumberOfOptionsPerQuestion] = useState(
    props.numberOfOptionsPerQuestion ? props.numberOfOptionsPerQuestion : 4
  );

  const questionsCorrectlyAnswered = useRef(0);
  const questionsWronglyAnswered = useRef(0);

  //const currentQuestion = useRef(null)

  const speak = (audio, onEndAction) => {
    if (audio === null || audio === undefined) return;

    // Stop previous audio
    if (activeTeacherAudio) {
      // console.log(activeTeacherAudio)
      activeTeacherAudio.stop();
    }

    // set the current audio as the active teacher/speaker audio
    setActiveTeacherAudio(audio);
    //console.log("NN",audio)

    audio.once("end", function () {
      setIsInstructorTalking(false);
      onEndAction && onEndAction();
    });

    setIsInstructorTalking(true);
    audio.play();
  };

  const generateOptions = useCallback(
    (
      options,
      questionLenght = 20,
      questionOptionsLenght = 4,
      rightAnswerPoints = 5,
      wrongAnswerPoints = -2
    ) => {
      let shuffledOptionsPool = shuffle(options);

      let generatedOptions = [];

      let questionCount = 0;

      for (
        let i = 0;
        i < questionLenght;
        i++ // shuffledOptionsPool.length; i++)
      ) {
        if (questionCount + 1 >= shuffledOptionsPool.length) {
          shuffledOptionsPool = [...shuffledOptionsPool, ...shuffle(options)];

          //questionCount = 0;
        }

        let letterOption = {
          ...shuffledOptionsPool[i],
          optionPoints: rightAnswerPoints,
        };

        //let questionOptions = [];
        console.log("Bank", letterOption);

        let otherOptions = shuffle(options)
          .filter((op) => op.id != letterOption.id)
          .slice(0, questionOptionsLenght - 1);

        questionCount++;

        let allOptions = [
          letterOption,
          ...otherOptions.map((oto) => {
            return { ...oto, optionPoints: wrongAnswerPoints };
          }),
        ];

        generatedOptions.push({
          id: uuidv4(),
          letterOption,
          options: [...allOptions],
        });
      }

      return generatedOptions;
    }
  );

  const optionPlayer = useRef(null);

  const sayOptionAnswer = useCallback((audio) => {
    // Clear and stop the previous audio.
    if (optionPlayer.current) clearInterval(optionPlayer.current);

    speak(audio);

    //keep playing the audio in a loop,
    optionPlayer.current = setInterval(() => speak(audio), 3000);
  });

  const rightSelectionSound = new Howl({
    src: Chime001,
  });

  const wrongSelectionSound = new Howl({
    src: Chime002,
  });

  rightSelectionSound.once("end", function () {
    //setIsInstructorTalking(false)
  });

  wrongSelectionSound.once("end", function () {
    //setIsInstructorTalking(false)
  });

  const clickHandler = (option) => {
    console.log("Option Clicked", option);
    setGamePoints((gp) => {
      if (gp + option.isCorrect > 0) {
        return gp + props.rightAnswerPoints;
      } else {
        return 0;
      }
    });

    if (option.isCorrect > 0) {
      //speak(audios[props.correctAnswerFeedbackAudio]);
      setShowPositiveFeedback(true);
      setTimeout(() => setShowPositiveFeedback(false), 2000);
      audios[props.correctAnswerFeedbackAudio] &&
        audios[props.correctAnswerFeedbackAudio].play();
      questionsCorrectlyAnswered.current =
        questionsCorrectlyAnswered.current + 1;

      // Clear and stop the previous audio.
      //if(optionPlayer.current) clearInterval(optionPlayer.current);

      // Clear and stop the previous audio.
      if (optionPlayer.current) clearInterval(optionPlayer.current);
      // Goes to the next question.
      setTimeout(getNextQuestion, 2000);
    } else {
      //speak(audios[props.wrongAnswerFeedbackAudio]);
      setShowNegativeFeedback(true);
      setTimeout(() => setShowNegativeFeedback(false), 2000);
      audios[props.wrongAnswerFeedbackAudio] &&
        audios[props.wrongAnswerFeedbackAudio].play();
      questionsWronglyAnswered.current = questionsWronglyAnswered.current + 1;
    }

    //getNextQuestion();
  };

  // HOC to Wrap the user defined click handler

  const [gameOptions, setGameOptions] = useState(() =>
    generateOptions(
      props.optionsBank,
      props.numberOfQuestions,
      props.numberOfOptionsPerQuestion,
      props.rightAnswerPoints,
      props.wrongAnswerPoints
    )
  ); //useState(props.gameOptions ?
  // props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}): []);

  const pauseGame = () => {
    console.log("GAME PAUSED", animationHandles);
    Object.keys(animationHandles).map((anh) => {
      animationHandles[anh].pause();
    });
  };

  const resumeGame = () => {
    Object.keys(animationHandles).map((anh) => {
      animationHandles[anh].resume();
    });
  };

  function setGameState(at, nt) {
    if (nt) return nt;

    if (at > 0) {
      return at - 1;
    } else {
      setIsGameOver(true);
      clearInterval(timerIdRef.current);
      props.onGameEnd &&
        props.onGameEnd({ gameTime: at, gamePoints: gamePoints });
      endGame();
      return 0;
    }
  }

  const [activityTime, setActivityTime] = useReducer(
    setGameState,
    initialActivityTime
  ); //useState(initialActivityTime);

  // starts the game
  const startGame = () => {
    setIsPlaying(() => {
      return true;
    });

    initGameState();
    props.onGameStart && props.onGameStart();
  };

  // Initialises the game state
  const initGameState = () => {
    //console.log("GENERATED", generateOptions(props.optionsBank));

    //setGameOptions(generateOptions(props.optionsBank));

    currentQuestionCount.current = 0;

    optionPlayer.current = null;

    completedTracks.current = [];

    // Shuffle these as a component state

    //let trackOptions = [...setUpCurrentGameOption(gameOptions[0].options, numberOfTracks)]
    setCurrentGameOption({ ...gameOptions[0] }); //, trackOptions:[...trackOptions]});

    console.warn("NEXT_Q", gameOptions[0]);

    //console.log("OOPS", setUpCurrentGameOption(props.gameOptions[0].options, 2))

    //setTrackOptions([...trackOptions]);

    // Say the letterOption
    gameOptions[0].letterOption &&
      sayOptionAnswer(audios[gameOptions[0].letterOption.audio]);

    timerIdRef.current && clearInterval(timerIdRef.current);
    timerIdRef.current = setInterval(setActivityTime, 1000);
  };

  function restartGame() {
    setIsPlaying(true);
    setIsGameOver(false);
    resumeGame();
    setActivityTime(initialActivityTime, true);
    setGamePoints(0);
    initGameState();
    //setGameOptions(props.gameOptions.map(go => {return {...go, clickHandler:wrapClickHandler(go.clickHandler)}}));
    //if(optionPlayer.current) clearInterval(optionPlayer.current);
    //timerIdRef.current && clearInterval(timerIdRef.current);
    //timerIdRef.current = setInterval(setActivityTime, 1000);
    props.onGameStart && props.onGameStart();
  }

  function endGame() {
    setIsPlaying(false);
    setIsGameOver(true);
    pauseGame();
    //resumeGame();
    //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
    if (optionPlayer.current) clearInterval(optionPlayer.current);
    clearInterval(timerIdRef.current);
  }

  const continueGame = () => {
    //setIsPlaying(true);
    setIsGamePaused(false);

    // Say the letterOption
    currentGameOption.letterOption &&
      sayOptionAnswer(audios[currentGameOption.letterOption.audio]);
    resumeGame();
    timerIdRef.current = setInterval(setActivityTime, 1000);
  };

  const pause = () => {
    //setIsPlaying(true);
    setIsGamePaused(true);
    pauseGame();
    //resumeGame();
    //props.onGameEnd && props.onGameEnd({gameTime:activityTime,gamePoints:gamePoints });
    if (optionPlayer.current) clearInterval(optionPlayer.current);

    clearInterval(timerIdRef.current);
  };

  useEffect(() => {
    return () => {
      if (optionPlayer.current) clearInterval(optionPlayer.current);
      clearInterval(timerIdRef.current);
    };
  }, []);

  const onLoad = (loadedAudios) => {
    setAudios(loadedAudios);

    //playInstructions(loadedAudios[props.gameIntroAudio]);
    /*if(loadedAudios[props.gameIntroAudio])
        {
            speak(loadedAudios[props.gameIntroAudio], ()=>setCanPlayGame(true))
        }
        else
        {
            setCanPlayGame(true);  
        }*/
  };

  const playInstructions = (audio) => {
    if (audio) {
      speak(audio, () => setCanPlayGame(true));
    } else {
      setCanPlayGame(true);
    }
  };

  /*// gets the total number of gameOption options
    const getTotalGameOptionOptions = (gameOptions) => {

        let totalOptions = 0;

        gameOptions.map(go => totalOptions +=go.length);

        return;
    }*/

  const completedTracks = useRef([]);

  //const totalQuestionCount = useRef(getTotalGameOptionOptions(props.gameOptions))

  const currentQuestionCount = useRef(0);

  // keeps track of displayed options per question.
  // This either navigates to the next question pool or
  // ends the game.
  /*const trackOptionsCompleted = (id) =>
    {
        completedTracks.current.push(id);

        if(completedTracks.current.length === gameOptions.length)
        {
            endGame();
        }
    }*/

  useEffect(() => {
    if (isGameOver) {
      let d = new Date();
      saveUsageProgressData({
        id: uuidv4(),
        points: gamePoints,
        totalPoints: props.numberOfQuestions * props.correctAnswerPoints,
        totalNumberOfQuestions: props.numberOfQuestions,
        questionsCorrectlyAnswered: questionsCorrectlyAnswered.current,
        questionsWronglyAnswered: questionsWronglyAnswered.current,
        activityType: "game",
        activityTitle: props.title,
        activitySubTitle: "Game 01",
        activityCode: props?.code,
        timeSpent: initialActivityTime - activityTime,
        activityTotalTime: initialActivityTime,
        activityDateTimeStamp: d.toISOString(), //`${d.getHours()}:${d.getMinutes()} - ${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
      });
    }
  }, [isGameOver]);

  // This is a callback to know when all the options
  // assigned to a floating container have been displayed
  // i.e. moved from bottom to top.
  /*  const trackOptionsCompleted = (id) =>
    {
        completedTracks.current.push(id);

        if(completedTracks.current.length === trackOptions.length)
        {           
            completedTracks.current = [];

            if(props.autoSelectNextQuestion)
            {
                setTimeout(getNextQuestion, 1000);  
                return;
            }
            // Since no option has been selected at this point,
            // The options are reset and displayed again.
            let newOptionValue = gameOptions[currentQuestionCount.current];
        
            if(newOptionValue)
            {
                // Loop with the same set options and a new id to force re-render
                setCurrentGameOption({...currentGameOption,  id:uuidv4()});//{...newOptionValue, id:uuidv4(), trackOptions:[...trackOptions]});
               
                 // Say the letterOption
                //newOptionValue.letterOption && sayOptionAnswer(audios[newOptionValue.letterOption.audio])

                //setTrackOptions(setUpCurrentGameOption(newOptionValue.options, 2));
            }

        }
    } */

  const getNextQuestion = useCallback(() => {
    currentQuestionCount.current++;

    if (currentQuestionCount.current === gameOptions.length) {
      endGame();
      return;
    }

    let newOptionValue = gameOptions[currentQuestionCount.current];

    if (newOptionValue) {
      console.warn("NEXT_Q", newOptionValue);
      newOptionValue.letterOption &&
        sayOptionAnswer(audios[newOptionValue.letterOption.audio]);

      // Say the letterOption

      //let newOptionsSet = setUpCurrentGameOption(newOptionValue.options, numberOfTracks);

      setCurrentGameOption({ ...newOptionValue }); //, trackOptions:[...newOptionsSet]});
      //setTrackOptions([...newOptionsSet]);
    }
  });

  // splits the options/answers into the defined tracks
  // by returning a multi-dimensional array.
  /*  const setUpCurrentGameOption = (originalGameOptionsSet, NoOfSplits = 1) => {
        if(originalGameOptionsSet)
        {
            let gameOptionsSet = shuffle(originalGameOptionsSet);

            let splitGameOptions = [];           

            let n = gameOptionsSet.length;

            let gameSetLength = gameOptionsSet.length;

            while(n > 0)
            {
                let m = NoOfSplits;

                while(m > 0 && n > 0) 
                {
                    
                    if((NoOfSplits-m) in splitGameOptions)
                    {
                        if(gameOptionsSet[gameSetLength-n])
                        {
                            splitGameOptions[NoOfSplits-m].push(gameOptionsSet[gameSetLength-n])
                        }
                    }
                    else
                    {
                        splitGameOptions[NoOfSplits-m] =  [gameOptionsSet[gameSetLength-n]]
                    }

                    m--;

                    n--;
                   
                }               
            }
            
           return splitGameOptions;
        }
    }
 */
  // Fisher–Yates shuffle, see the Wikipedia article
  // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
  function shuffle(sourceArray) {
    let array = [...sourceArray]; //To prevent mutation of source

    var m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  return (
    <PreloaderView
      onLoad={onLoad}
      images={props.preloadedResources?.images}
      audios={props.preloadedResources?.audios}
    >
      {!isPlaying ? (
        isGameOver ? (
          <div
            style={{
              height: "100vh",
              zIndex: 51,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              position: "absolute",
              backgroundColor: "rgba(10,10,10,0.9)",
            }}
          >
            <div style={{ fontSize: 40, fontWeight: "bold", color: "orange" }}>
              <img src={GameOverImg} />
            </div>
            <PlayButton onClick={() => restartGame()} caption={"Play Again"} />
          </div>
        ) : (
          <div
            style={{
              minHeight: 300,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              height: "100%",
              position: "relative",
              backgroundColor: "#85ccfb",
              background: `no-repeat center/100% url(${
                props.gameBackground || defaultbackground
              })`,
              backgroundSize: "cover",
            }}
          >
            <div className={"game-title"}>{props.title || "Game 1"}</div>
            {/*If an intro audio is specified*/}
            {props.gameIntroAudio && (
              <div
                style={{
                  position: "relative",
                  backgroundColor: "#e4f2f9",
                  width: 200,
                  left: 10,
                  bottom: 10,
                  borderRadius: "50%",
                  height: 200,
                  padding: 5,
                  border: "5px solid #087bc7",
                  boxShadow: "3px 3px 3px rgba(100,100,100,0.4)",
                }}
              >
                <Teacher
                  speaker="main"
                  //cursor={pointer3}
                  type="default"
                  extra={{ glow: !canPlayGame, talk: isInstructorTalking }}
                  onClick={() => playInstructions(audios[props.gameIntroAudio])}
                />
              </div>
            )}
            <PlayButton
              disabled={!canPlayGame}
              onClick={() => startGame()}
              caption={"Play"}
            />
          </div>
        )
      ) : (
        <div
          style={{
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            position: "relative",
            justifyContent: "",
            backgroundColor: "#FF9900",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              overflow: "hidden",
              flexGrow: 1,
              width: "100%",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FF9900",
              background: `no-repeat center/100% url(${
                props.gameBackground || defaultbackground
              })`,
              backgroundSize: "cover",
            }}
          >
            {/* {currentGameOption && currentGameOption.trackOptions && currentGameOption.trackOptions.map((fo, ind, arr) => {
                    return(<FloatingContainer totalNumberOfTracks={numberOfTracks} talkAction={speak} trackOptionsCompleted={trackOptionsCompleted} setAnimationHandles={setAnimationHandles} audios={audios} id={`${ind} ${currentGameOption.id}`} key={`${ind} ${currentGameOption.id}`} index={ind} optionImage={fo.optionImage} optionsData={fo} clickHandler={clickHandler} text={fo} optionDisplayDuration={props.optionDisplayDuration || 2000}/>)
                })}*/}
            <WritingBox
              showSilhouette={props.showSilhouette}
              letter={currentGameOption.letterOption.letter}
              onWritingCompleted={clickHandler}
            />
          </div>
          {/** Score Board */}
          <ScoreBoard
            isInstructorTalking={isInstructorTalking}
            gamePoints={gamePoints}
            canPause={true}
            enableUndo={false}
            activityTime={activityTime}
            pauseGame={pause}
            endGame={endGame}
          />

          {/**Notification Popup */}
          {isGamePaused && (
            <div
              style={{
                height: "100vh",
                zIndex: 51,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                position: "absolute",
                backgroundColor: "rgba(10,10,10,0.9)",
              }}
            >
              <PlayButton onClick={() => continueGame()} caption={"Continue"} />
            </div>
          )}

          {/**Selected Option Feedback*/}
          {showPositiveFeedback && (
            <div
              style={{
                height: "100vh",
                zIndex: 51,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                position: "absolute",
                backgroundColor: "rgba(10,10,10,0.2)",
              }}
            >
              <Confetti>
                <div
                  style={{
                    height: "100vh",
                    zIndex: 51,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className={"strong-pulsate"}
                    style={{ maxWidth: "50%" }}
                    src={showPositiveFeedback ? CorrectImg : WrongImg}
                  />
                </div>
              </Confetti>
            </div>
          )}
          {showNegativeFeedback && (
            <div
              style={{
                height: "100vh",
                backgroundColor: "rgba(10,0,0,0.5)",
                zIndex: 51,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                position: "absolute",
              }}
            >
              <div
                style={{
                  height: "100vh",
                  zIndex: 51,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className={"strong-pulsate-rotate"}
                  style={{ maxWidth: "50%" }}
                  src={showPositiveFeedback ? CorrectImg : WrongImg}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </PreloaderView>
  );
};

export default Game11;

/* const Game11 = (props) => {

  return <div 
  style={{
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
  }}>
<WritingBox letter={"w"}/>
<WritingBox letter={"i"}/>
<WritingBox letter={"n"}/>
<WritingBox letter={"j"}/>
<WritingBox letter={"6"}/>
  </div>
}

export default Game11; */
