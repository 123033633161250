import React from 'react';
import styled, { keyframes } from 'styled-components';
import cloudImage from './assets/cloudsvg.svg';

const CloudBoardContainer = styled.div`
  width: ${(props) => props.width || '450px'};
  height: ${(props) => props.height || '400px'};
  background-image: url(${cloudImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
  @media (max-width: 768px) {
    width: 100vw;
    height: 30vh;
    background-size: 100%;
    background-position: center center;
  }
`;

const Content = styled.div`
  font-family: 'Aclonica', sans-serif;
  font-size: 2em;
  color: ${(props) => {
    switch (props.answerStatus) {
      case 'correct':
        return '#4CAF50';
      case 'incorrect':
        return '#F44336';
      case 'pending':
        return 'gray';
      default:
        return '#000';
    }
  }};
  font-weight: bold;
  text-align: center;
`;

const MainBoard = ({ width, height, children, answerStatus }) => {
  return (
    <CloudBoardContainer width={width} height={height}>
      <Content answerStatus={answerStatus}>{children}</Content>
    </CloudBoardContainer>
  );
};

export default MainBoard;