import styled from "styled-components";

const StyledGridContainer = styled.div`
  height: 15vw;
  width: 15vw;
  background-image: url("/images/Box-01.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  position: relative;
  cursor: ${(props) => `url(${props.$selectCursor}), pointer`};

  &:hover {
    transform: scale(1.05) rotate(3deg);
  }

  span {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    width: 80%;
    word-wrap: break-word;
  }
  img {
    object-fit: scale-down;
    max-width: 80%;
    max-height: 80%;
  }
  @media screen and (min-width: 720px) {
    height: 24vw;
    width: 24.2vw;
  }
  @media screen and (min-width: 720px) and (orientation: portrait) {
    height: 30vw;
    width: 30.2vw;
  }
  // Modify tablet breakpoint and add iPad-specific sizing
  @media screen and (min-width: 720px) and (max-width: 1024px) {
    height: 22vw; // Reduced from 24vw
    width: 22vw; // Reduced from 24.2vw
  }

  // iPad Mini/Portrait-specific adjustment
  @media screen and (min-width: 720px) and (max-width: 1024px) and (orientation: portrait) {
    height: 28vw; // Reduced from 30vw
    width: 32vw; // Reduced from 30.2vw
  }

  @media screen and (min-width: 1200px) {
    height: 20vw;
    width: 23vw;
  }
  @media screen and (min-width: 1550px) {
    height: 16vw;
    width: 19vw;
  }
  @media screen and (min-width: 2400px) {
    height: 12vw;
    width: 14vw;
  }

  @media screen and (max-width: 720px) {
    width: 46vw;
    height: 40.2vw;
  }

  @media screen and (max-width: 380px) {
    width: 36vw;
    height: 36.2vw;
  }
`;

const TapCounter = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

function NewGrid({
  content,
  selectGrid,
  selected,
  isChecked,
  getGridTaps,
  selectCursor,
}) {
  const taps = getGridTaps(content.id);

  return (
    <StyledGridContainer
      onClick={() => selectGrid(content.id)}
      $selectCursor={selectCursor}
    >
      {taps > 0 && <TapCounter>{taps}</TapCounter>}
      {content.image ? (
        <img src={content.image} alt={content.text || ""} />
      ) : (
        <span>{content.text}</span>
      )}
    </StyledGridContainer>
  );
}

export default NewGrid;
