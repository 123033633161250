const audioUrls = {
    "1": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/1.mp3",
    "2": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/2.mp3",
    "3": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/3.mp3",
    "4": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/4.mp3",
    "5": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/5.mp3",
    "6": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/6.mp3",
    "7": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/7.mp3",
    "8": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/8.mp3",
    "9": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/9.mp3",
    "10": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/10.mp3",
    "11": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/11.mp3",
    "12": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/12.mp3",
    "13": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/13.mp3",
    "14": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/14.mp3",
    "15": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/15.mp3",
    "16": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/16.mp3",
    "17": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/17.mp3",
    "18": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/18.mp3",
    "19": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/19.mp3",
    "20": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/20.mp3",
    "20s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/20s.mp3",
    "30": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/30.mp3",
    "30s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/30s.mp3",
    "40": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/40.mp3",
    "40s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/40s.mp3",
    "50": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/50.mp3",
    "50s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/50s.mp3",
    "60": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/60.mp3",
    "60s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/60s.mp3",
    "70": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/70.mp3",
    "70s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/70s.mp3",
    "80": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/80.mp3",
    "80s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/80s.mp3",
    "90": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/90.mp3",
    "90s": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/90s.mp3",
    "100": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/100.mp3",
    whatIs: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/what_is.mp3",
    is: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/is_c.mp3",
    plus: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/plus.mp3",
    minus: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/minus.mp3",
    multipliedby: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/multiplied_by.mp3",
    dividedby: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/divided_by.mp3",
    fillerintro: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/gen_ques.mp3",
    correctsound: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/correctAnswer.mp3",
    correct: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/correct.mp3",
    incorrectsound: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/assessment_audio/wrongAnswer.mp3",
    thatsright: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/thatsright.mp3",
    fantastic: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/fantastic.mp3",
    goodjob: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/goodjob.mp3"
};

export default audioUrls;