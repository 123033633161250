import { useState, useEffect } from "react";

const generateRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const getOperatorSymbol = (operatorId) => {
  switch (operatorId) {
    case 1:
      return "+";
    case 2:
      return "-";
    case 3:
      return "/";
    case 4:
      return "*";
    default:
      return "+";
  }
};

const calculateAnswer = (operands, operatorId) => {
  switch (operatorId) {
    case 1:
      return operands.reduce((acc, cur) => acc + cur, 0);
    case 2:
      return operands.reduce((acc, cur) => acc - cur);
    case 3:
      return operands.reduce((acc, cur) => acc / cur);
    case 4:
      return operands.reduce((acc, cur) => acc * cur);
    default:
      return operands.reduce((acc, cur) => acc + cur, 0);
  }
};

const generateOperands = (number_of_operands, operatorId, min_num, max_num) => {
  let operands = [];

  if (operatorId === 2) {
    operands.push(generateRandomNumber(min_num, max_num));
    for (let i = 1; i < number_of_operands; i++) {
      operands.push(generateRandomNumber(min_num, operands[i - 1]));
    }
  } else if (operatorId === 3) {
    let dividend = generateRandomNumber(min_num, max_num);
    let divisors = [];

    for (let i = 1; i < number_of_operands; i++) {
      let divisor = generateRandomNumber(1, max_num);
      while (dividend % divisor !== 0) {
        divisor = generateRandomNumber(1, max_num);
      }
      divisors.push(divisor);
    }
    operands = [dividend, ...divisors];
  } else {
    operands = Array.from({ length: number_of_operands }, () =>
      generateRandomNumber(min_num, max_num)
    );
  }

  return operands;
};

const generateQuestion = (
  id,
  number_of_operands,
  operatorId,
  min_num,
  max_num
) => {
  const operands = generateOperands(
    number_of_operands,
    operatorId,
    min_num,
    max_num
  );
  const questionText = `What is ${operands.join(
    ` ${getOperatorSymbol(operatorId)} `
  )} ?`;
  const correctAnswer = calculateAnswer(operands, operatorId);

  // Check if the correct answer is more than 100
  if (correctAnswer > 100) {
    return generateQuestion(
      id,
      number_of_operands,
      operatorId,
      min_num,
      max_num
    ); // Generate a new question
  }

  const buttons = [
    "greenActionButton",
    "redActionButton",
    "yellowActionButton",
    "purpleActionButton",
  ];

  const correctButtonIndex = Math.floor(Math.random() * buttons.length);
  const options = buttons.map((buttonId, index) => {
    if (index === correctButtonIndex) {
      return {
        id: buttonId,
        title: correctAnswer.toString(),
        correctAnswer: true,
      };
    } else {
      let incorrectAnswer =
        correctAnswer + (index === 0 ? 1 : index === 1 ? -1 : index + 1);

      // Ensure the incorrect answer is within the range
      while (incorrectAnswer > 100 || incorrectAnswer === correctAnswer) {
        incorrectAnswer = generateRandomNumber(min_num, max_num);
      }

      return {
        id: buttonId,
        title: incorrectAnswer.toString(),
        correctAnswer: false,
      };
    }
  });

  const shuffledOptions = options.sort(() => Math.random() - 0.5);

  return {
    id: id.toString(),
    questionText,
    options: shuffledOptions,
    operands,
    operatorId,
  };
};

const useGenerateMathQuestions = ({
  number_of_operands,
  operator_id,
  min_num,
  max_num,
}) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const generateQuestions = () => {
      const generatedQuestions = Array.from({ length: 20 }, (_, index) =>
        generateQuestion(
          index + 1,
          number_of_operands,
          operator_id,
          min_num,
          max_num
        )
      );
      setQuestions(generatedQuestions);
    };

    generateQuestions();
    console.log("Generated questions:", questions);
  }, [number_of_operands, operator_id, min_num, max_num]);

  return questions;
};

export default useGenerateMathQuestions;
