import { useCallback } from 'react';
import { Howl } from 'howler';

import audioUrls from './databank/audioUrls';

const useBiamutaAI = () => {

    const biamuta_TextToSpeech = useCallback((text, callback) => {
        const normalizedText = text.toLowerCase().replace(/\s+/g, ' ').trim();
        const audioSequence = [];
      
        const numberToAudioUrls = (num) => {
          const urls = [];
          if (num >= 100) {
            const hundreds = Math.floor(num / 100) * 100;
            urls.push(audioUrls[hundreds.toString()]);
            num %= 100;
          }
          if (num >= 20) {
            const tens = Math.floor(num / 10) * 10;
            urls.push(audioUrls[tens.toString()]);
            num %= 10;
          }
          if (num > 0) {
            urls.push(audioUrls[num.toString()]);
          }
          return urls;
        };
      
        const parts = normalizedText.match(/(\d+|[+\-*/]|\w+|\?)/g) || [];
      
        for (let i = 0; i < parts.length; i++) {
          const part = parts[i].trim();
      
          if (/^\d+$/.test(part)) {
            
            audioSequence.push(...numberToAudioUrls(parseInt(part, 10)));
          } else if (['+', '-', '*', '/'].includes(part)) {
            
            const operatorAudioMap = {
              '+': 'plus',
              '-': 'minus',
              '*': 'multipliedby',
              '/': 'dividedby',
            };
            audioSequence.push(audioUrls[operatorAudioMap[part]]);
          } else if (part === 'what' && i + 1 < parts.length && parts[i + 1].trim() === 'is') {
            audioSequence.push(audioUrls['whatIs']);
            i++; 
          } else {
            
            const wordAudioUrl = audioUrls[part];
            if (wordAudioUrl) {
              audioSequence.push(wordAudioUrl);
            } else {
              console.warn(`No audio found for: ${part}`);
            }
          }
        }
      
        const playAudioSequence = (urls) => {
          if (urls.length === 0) return;
      
          let index = 0;
      
          const playNext = () => {
            if (index >= urls.length) {
              if (typeof callback === 'function') {
                callback();
              }
              return;
            }
      
            const sound = new Howl({
              src: [urls[index]],
              onend: () => {
                index++;
                playNext();
              },
              onloaderror: (id, error) => {
                console.error(`Failed to load audio ${urls[index]}: ${error}`);
              },
            });
      
            sound.play();
          };
      
          playNext();
        };
      
        playAudioSequence(audioSequence);
    }, []);

    const biamuta_TranslateText = useCallback((param) => {
        console.log('still pending', param);
    }, []);

    return {
        biamuta_TextToSpeech,
        biamuta_TranslateText
    };
};

export default useBiamutaAI;