import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  GET_LESSON_ACTIVITY_ENDPOINT,
  lessonsDataBaseURL,
} from "../constants/URLs";
import GreyLogo from "../assets/images/grey_logo.svg";
import styled from "styled-components";
import ErrorView from "../components/ErrorView";
import CustomLoader from "../components/CustomLoader";
import Lesson2 from "../modes/lessons/mode2/mode2";
import Mode3 from "../modes/lessons/mode3/mode3.component";
import Mode4 from "../modes/lessons/mode4/Mode4";
import WritingLessonMode from "../modes/lessons/writingMode/WritingLessonMode";
import PartsOfTheBody from "../modes/lessons/part-of-the-body";
import ConversationMode from "../modes/lessons/conversationMode";
import {
  GridLearnMode,
  GridLearnMode2,
} from "../modes/lessons/grid-learn";
import { ReadAloudMode } from "../modes/lessons/read-aloud";
import EnglishAssessment01 from "../modes/assessments/EnglishAssessment01";



const LessonNotFoundWrapper = styled.div`
  padding-top: 60px;
  height: 100vh;
  /* height: calc(100vh - 60px); */
  width: 100%;
  background: #231705;
  background: #ffcf94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
`;

const LessonModeSelector = ({ data }) => {
  console.log("MODE", data.activityMode);
  switch (data.activityMode) {
    case "mode2":
      return <Lesson2 data={data} />;
    case "mode3":
      return <Mode3 data={data} />;
    case "mode4":
      return <Mode4 data={data} />;
    case "writing-lesson":
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WritingLessonMode {...data} />
        </div>
      );
    case "conversation":
      return <ConversationMode data={data} />;
    case "parts-of-the-body":
      return <PartsOfTheBody data={data} />;
    case "read-aloud":
      return <ReadAloudMode data={data} />;
    case "read-aloud2":
        //return <EnglishAssessment01 data={data} />;
        return <EnglishAssessment01 />;
    case "grid-learn":
      return <GridLearnMode data={data} />;
    case "grid-learn2":
      return <GridLearnMode2 data={data} />;
    default:
      return (
        <LessonNotFoundWrapper>
          <img style={{ width: 200, opacity: 0.4 }} src={GreyLogo} />
          <div
            style={{ fontSize: 32, color: "#c16c35", padding: 20 }}
          >
            Lesson not found!
          </div>
        </LessonNotFoundWrapper>
      );
  }
};

const Lessons = (props) => {
  const { lessonCode } = useParams();
  // declare the state for setting the data
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);

  // load the lesson data from the back-end using the lesson code
  // load the lesson data from the back-end using the lesson code
  useEffect(() => {
    setData(null);
    let dataurl = lessonsDataBaseURL;

    const loadData = async () => {
      await axios
        .get(`${GET_LESSON_ACTIVITY_ENDPOINT}&code=${lessonCode}`, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((response) => {
          console.log("data received");
          console.log(response.data.jsonData);
          setData({ ...response.data.jsonData });
        })
        .catch((error) => {
          console.log("something was wrong with the fetch");
          setIsError(true);
          setData(null);
        });
    };

    loadData();
  }, [lessonCode]);

  return (
    <Fragment>
      {isError ? (
        <ErrorView
          message={"An error has occured while loading data...."}
        />
      ) : data ? (
        <LessonModeSelector data={data} />
      ) : (
        <CustomLoader />
      )}
    </Fragment>
  );
};

export default Lessons;
