import React, { useContext, useState } from "react";
import SpeakerPointer from "../../../shared/SpeakerPointer";
import GridContainer from "./components/grid-container/GridContainer";
import { NextSection, PrevSection } from "./components/section/Section";
import { StyledWrapper, Loader } from "./GridLearn.styles";
import { LessonStateContext } from "../../../shared/sharedLayout";
import TypewriterText from "./components/TypewriterText";
import BGImage from "./png-resources/BG.png";

const grids = 6;
function GridLearn({ data }) {
  const [items] = useState(() => data.mode);
  const [currSection, setCurrSection] = useState(1);
  const [currGrids, setCurrGrids] = useState(
    items.slice(currSection - 1, currSection + grids - 1)
  );
  const [selectedGrid, setSelectedGrid] = useState(-1);
  const [playedGrids, setPlayedGrids] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  const [gridTaps, setGridTaps] = useState({}); // Track taps for each grid
  const { playAudio, stopAudio } = useContext(LessonStateContext);

  const checkGrid = (grid) => playedGrids.indexOf(grid) < 0;

  const incrementGridTaps = (gridId) => {
    setGridTaps((prevTaps) => ({
      ...prevTaps,
      [gridId]: (prevTaps[gridId] || 0) + 1,
    }));
  };

  const getGridTaps = (gridId) => {
    return gridTaps[gridId] || 0;
  };

  const selectGrid = (grid) => {
    stopSpeaking();
    setSelectedGrid(grid - 1);
    incrementGridTaps(grid); // Increment tap count when grid is selected
    if (checkGrid(grid)) setPlayedGrids([...playedGrids, grid]);
    playAudio(
      items[grid - 1]?.audio,
      () => {
        setSpeaking(true);
      },
      () => {
        setSpeaking(false);
      }
    );
  };

  const stopSpeaking = () => {
    stopAudio();
    setSpeaking(false);
  };

  React.useEffect(() => {
    setCurrGrids(items.slice(currSection - 1, currSection + grids - 1));
  }, [currSection, items]);

  const prevSection = () => {
    stopSpeaking();
    if (currSection === 1) {
      return;
    }
    setSelectedGrid(-1);
    setCurrSection((prevState) => (prevState -= grids));
  };
  const nextSection = () => {
    stopSpeaking();
    if (currSection + grids >= items.length) return;
    setSelectedGrid(-1);
    setCurrSection((prevState) => (prevState += grids));
  };

  return (
    <StyledWrapper
      $cursor={data.cursor}
      $selectCursor={data.selectCursor}
      $bg={BGImage}
    >
      <div className="grid-teacher">
        <SpeakerPointer
          isTalking={speaking}
          charID={"main"}
          size={"small"}
          handleClick={() => {}}
          cursor={data.selectCursor}
        />
      </div>

      {/* <Loader /> */}
      <TypewriterText
        text={"This is the way we start our day early in the morning."}
      />
      <GridContainer
        selectGrid={selectGrid}
        selectCursor={data.selectCursor}
        currGrids={currGrids}
        selected={selectedGrid}
        checkGrid={checkGrid}
        getGridTaps={getGridTaps} // Pass tap count getter to GridContainer
      />
      <div className="sectionBtns">
        <PrevSection
          prevSection={prevSection}
          selectCursor={data.selectCursor}
          isFirst={currSection === 1}
        />
        <NextSection
          nextSection={nextSection}
          selectCursor={data.selectCursor}
          isLast={currSection + grids >= items.length}
        />
      </div>
      <div className="page-num">
        <span>Page {Math.ceil(1 + (currSection - 1) / 6)}</span> of{" "}
        <span>{Math.ceil(items.length / grids)}</span>
      </div>
    </StyledWrapper>
  );
}

export default GridLearn;
