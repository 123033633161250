import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import useGenerateMathQuestions from "./hooks/mathassessment01/useGenerateMathsQuestions";
import ProgressBar from "./components/mathassessment01/ProgressBar";
import MainBoard from "./components/mathassessment01/MainBoard";
import AssetShapes from "./components/mathassessment01/AssetShapes";
import PopUp from "../../components/PopUp/PopUp";
import SmileySVG from "./components/mathassessment01/assets/smiley.svg";
import FrowneySVG from "./components/mathassessment01/assets/frowney.svg";
import ResultAnimation from "./components/mathassessment01/animationeffects/ResultAnimation";
import boardPagebackground from "./images/User page Board.svg";
import clickSound from "../assessments/audio/click01.wav";
import { usePlayAudioSimple } from "../../hooks/usePlayAudioSimple";
import useBiamutaAI from "../../hooks/useBiamutaAI";
import PreloaderView from "../../hocs/PreloaderView";
import SpeakerPointer from "../../shared/SpeakerPointer";
import Filler from "../lessons/components/filler/Filler";
import {useMediaQuery} from "@material-ui/core";


const clickAudio = new Audio(clickSound);

const MathAssessment01Container = styled.div`
    cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_cursor.png) 10 10, auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff8e1;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const BoardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 30px 0 0 92px;

    @media (max-width: 768px) {
        margin: 2px 0;
    }
        
    @media (min-width: 375px) and (max-width: 375) and (orientation: portrait) { 
        margin: 5% 0;
    }
    
    @media (min-width: 390px) and (orientation: portrait) {
        margin: 5% 0;
    }

    @media (min-width: 412px) and (max-width: 430px) and (orientation: portrait){ 
        margin: 5% 0;
    }
    
    @media (max-width: 768px) and (orientation: portrait) {
        margin: 5% 0;
    }
    
    @media (max-width: 932px) and (orientation: landscape) {
      margin: 1% 0%;
    }
    
    @media (min-width: 3440px) {
      margin: 170px 0 0 375px;
    }

    @media (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
      margin-left: 0;
    }

`;

const MainBoardWrapper = styled.div`
    display: flex;
    justify-content: flex-start;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const PopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
`;

const PopupContent = styled.div`
    padding: 10px 0;
    font-size: 1.2rem;
    color: #333333;
`;

const PopupButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background: #ff8c00;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;

    &:hover {
        background: #e67e22;
    }
`;

const defaultAudio = {
    whatIs: 'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/what_is.mp3',
    base: 'https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/'
}

const MathAssessment01 = (props) => {
    const smallHeader = useMediaQuery("(max-width:600px)");
    const mathProps = {
        number_of_operands: 2,
        operator_id: 1,
        min_num: 1,
        max_num: 99,
    };

    const { number_of_operands, operator_id, min_num, max_num } = mathProps;

    // Use the hook at the top level of the component
    const questions = useGenerateMathQuestions({
        number_of_operands,
        operator_id,
        min_num,
        max_num,
    });

    const [audio, setAudio] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [mainBoardContent, setMainBoardContent] = useState("");
    const [options, setOptions] = useState([]);
    const [progress, setProgress] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showCongratsPopup, setShowCongratsPopup] = useState(false);
    const [popUpContent, setPopUpContent] = useState("");
    const [answerStatus, setAnswerStatus] = useState(null);
    const [showEffect, setShowEffect] = useState(false);
    const [audioFilesToPreload, setAudioFilesToPreload] = useState([]);
    const [isPreloaded, setIsPreloaded] = useState(false);
    const [isPlayingIntroAudio, setIsPlayingIntroAudio] = useState(false)
    const [isStarted, setIsStarted] = useState(false);
    const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
    const [boardDimensions, setBoardDimensions] = useState({
        width: "550px",
        height: "300px",
    });
    const [showFiller, setShowFiller] = useState(true);
    

    const { biamuta_TextToSpeech } = useBiamutaAI();
    const { playArray, assignAudio, playAudio, playFinished, stopAudio } = usePlayAudioSimple();

    const [isTeacherNotTalking, setIsTeacherNotTalking] = useState(!playFinished);

    const correctImageSrcArray = [SmileySVG, SmileySVG, SmileySVG, SmileySVG];
    const incorrectImageSrcArray = [FrowneySVG, FrowneySVG, FrowneySVG, FrowneySVG];

    const convertNumberToArray = useCallback((num) => {
        if (num <= 20) {
            return [`${defaultAudio.base}${num}.mp3`];
        }

        const result = [];
        let remaining = num;
        // Handle hundreds
        if (remaining >= 100) {
            const hundreds = Math.floor(remaining / 100);
            if (remaining % 100 === 0) {
                result.push(`${defaultAudio.base}${remaining}.mp3`);
                remaining %= 100;
            } else {
                result.push(`${defaultAudio.base}${hundreds}00s.mp3`);
                remaining %= 100;
                if (remaining > 0) {
                    result.push('and');
                }
            }
        }
        if (remaining > 20) {
            const tens = Math.floor(remaining / 10);
            if (remaining % 10 === 0) {
                result.push(`${defaultAudio.base}${remaining}.mp3`);
            } else {
                result.push(`${defaultAudio.base}${tens}0s.mp3`);
                remaining %= 10;
                if (remaining > 0) {
                    result.push(`${defaultAudio.base}${remaining}.mp3`);
                }
            }
        } else if (remaining > 0) {
            result.push(`${defaultAudio.base}${remaining}.mp3`);
        }

        return result;
    }, []);

    const [fillerSpeakerClicked, setFillerSpeakerClicked] = useState(false)
    const [fillerSpeakerTalking, setFillerSpeakerTalking] = useState(false)

    const data = {
        "BG": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/conversationMode/Background-01.svg",
        "GameController": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/conversationMode/GameController.svg",
        "activityMode": "conversation",
        "activityName": "Conversations",
        "activityTitle": "John and Tobi",
        "backgroundMusic": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/mode4_EN_01/mode4_background.mp3",
        "convo": [
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_1.mp3",
            "speaker": true,
            "text": "Hello. My name is John. What is your name?",
            "timestamps": [0, 1.14, 1.48, 1.91, 2.2, 3.34, 3.77, 4.07, 4.4]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_2.mp3",
            "speaker": false,
            "text": "My name is Tobi.",
            "timestamps": [0, 0.32, 0.76, 1.06]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_3.mp3",
            "speaker": true,
            "text": "How old are you Tobi? ",
            "timestamps": [0, 0.36, 0.8, 1.12, 1.39]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_4.mp3",
            "speaker": false,
            "text": "I am six years old. How old are you? ",
            "timestamps": [0, 0.21, 0.56, 0.89, 1.25, 1.98, 2.36, 2.72, 3.04]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_5.mp3",
            "speaker": true,
            "text": "I am seven years old.",
            "timestamps": [0, 0.19, 0.52, 0.89, 1.28]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_6.mp3",
            "speaker": true,
            "text": "Nice to meet you Tobi.?",
            "timestamps": [0, 0.47, 0.6, 0.97, 1.2]
          },
          {
            "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5_7.mp3",
            "speaker": false,
            "text": "Nice to meet you John ",
            "timestamps": [0, 0.47, 0.6, 0.94, 1.2]
          }
        ],
        "cursor": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_cursor.png",
        "gameLink": "/game/conversation-game",
        "intro": {
          "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/gen_ques.mp3",
          "background": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/welcomeBG.svg",
          "navForwardBg": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForward.svg",
          "navForwardBlueBg": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForwardBlue.svg",
          "navForwardGray": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/lesson2/navForwardGray.svg",
          "placeHolder": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
          "rive": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher.riv"
        },
        "outro": {
          "audio": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/audio/lessons/conversationMode/JohnTobi/e1l5outro.mp3"
        },
        "riveSpeakers": [
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/john.riv",
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/tobi.riv"
        ],
        "selectCursor": "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg",
        "smallSpeakers": [
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/JohnFace.svg",
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/TobiFace.svg"
        ],
        "speakersPlaceHolder": [
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable1.svg",
          "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg"
        ]
    };

    useEffect(() => {
        setIsTeacherNotTalking(playFinished);
    }, [playFinished]);

    useEffect(() => {
        if (questions.length > 0) {
            const audioFiles = questions.flatMap(question => {
                const operatorAudio = `${defaultAudio.base}${
                    question.operatorId === 1 ? 'plus' :
                        question.operatorId === 2 ? 'minus' :
                            question.operatorId === 3 ? 'multiplied_by' :
                                'divided_by'
                }.mp3`;

                // Include audio for options
                const optionAudios = question.options.flatMap(option =>
                    convertNumberToArray(Number(option.title))
                );

                return [
                    defaultAudio.whatIs,
                    ...convertNumberToArray(question.operands[0]),
                    operatorAudio,
                    ...convertNumberToArray(question.operands[1]),
                    ...optionAudios
                ];
            });

            const additionalAudios = [
                `${defaultAudio.base}thatsright.mp3`,
                `${defaultAudio.base}fantastic.mp3`,
                `${defaultAudio.base}goodjob.mp3`,
                `${defaultAudio.base}correct.mp3`,
                `${defaultAudio.base}correctAnswer.mp3`,
                `${defaultAudio.base}wrongSoundOnly.mp3`
            ];

            setAudioFilesToPreload([...new Set([...audioFiles, ...additionalAudios, data.intro.audio, data.outro.audio])]);
        }
    }, [questions, convertNumberToArray]);

    const questionTask = () => {
        if (questions.length > 0 && isPreloaded) {
            const currentQuestion = questions[currentQuestionIndex];
            setMainBoardContent(currentQuestion.questionText);
            setOptions(currentQuestion.options);
            setAnswerStatus(null);
            setShowEffect(false);

            const operatorAudio = `${defaultAudio.base}${
                currentQuestion.operatorId === 1 ? 'plus' :
                    currentQuestion.operatorId === 2 ? 'minus' :
                        currentQuestion.operatorId === 3 ? 'multiplied_by' :
                            'divided_by'
            }.mp3`;

            const questionArray = [
                defaultAudio.whatIs,
                ...convertNumberToArray(currentQuestion.operands[0]),
                operatorAudio,
                ...convertNumberToArray(currentQuestion.operands[1])
            ];
            playArray(questionArray);
        }
    };

    useEffect(() => {

        if (showFiller) return;
        questionTask();
        
    
    }, [currentQuestionIndex, questions, isPreloaded, convertNumberToArray, playArray]);

    useEffect(() => {
        const updateDimensions = () => {
            
            // For wider screens
            if (window.innerWidth >= 3440) {
                setBoardDimensions({
                    //width: "60vw", // 70% of the viewport width
                    //height: "15vw" // 30% of the viewport height
                });
            } 
            // For smaller screens (mobile/tablet)
            else if (window.innerWidth <= 768) {
                setBoardDimensions({
                    width: "70vw",
                    height: "25vh"
                });
            } else {
                setBoardDimensions({
                    width: "550px",
                    height: "250px"
                });
            }
        };

        window.addEventListener("resize", updateDimensions);
        updateDimensions();

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if (isStarted && !isAssessmentCompleted) {
            console.log('hello worl');
        } else {
            //do nothing
        }
    }, [isStarted, isAssessmentCompleted]);

    const handleShapeClick = (action) => {
        switch (action) {
            case "leftArrowButton":
                setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                break;
            case "rightArrowButton":
                setCurrentQuestionIndex((prevIndex) =>
                    Math.min(prevIndex + 1, questions.length - 1)
                );
                break;
            default:
                const selectedOption = options.find(
                    (option) => option.title === action
                );
                if (selectedOption) {
                    // get the array for the number
                    const optionArray = convertNumberToArray(Number(selectedOption.title))
                    //play the array with the registered callback
                    console.log('trying to play option array: ',optionArray)
                    playArray(optionArray, ()=>{},(count, isEnded) =>{
                        if(isEnded){
                            // this is the last audio of the array played.
                            if(selectedOption.correctAnswer){
                                setAnswerStatus('correct')
                                setShowEffect(true)
                                setProgress((prevProgress) => Math.min(prevProgress + 1, 20));
                                const messages = ["correct","thatsright", "fantastic", "goodjob"];
                                const randomMessage =
                                    messages[Math.floor(Math.random() * messages.length)];
                                playAudio(`${defaultAudio.base}${randomMessage}.mp3`, ()=>{},() =>{
                                    setCurrentQuestionIndex((prevIndex) =>
                                        Math.min(prevIndex + 1, questions.length - 1)
                                    );
                                })
                                playAudio( `${defaultAudio.base}correctAnswer.mp3`)
                            }else{
                                setAnswerStatus("incorrect");
                                setShowEffect(true);
                                playAudio( `${defaultAudio.base}wrongSoundOnly.mp3`, ()=>{
                                    setIsTeacherNotTalking(true);
                                }, ()=>{
                                    setCurrentQuestionIndex((prevIndex) =>
                                        Math.min(prevIndex + 1, questions.length - 1)
                                    );
                                })
                                
                                setTimeout(() => {
                                    setShowEffect(false);
                                }, 2000);
                            }
                        }


                    })

                } else {
                    setPopUpContent("Option not available");
                    setShowPopUp(true);
                }
                break;
        }
    };

    const closePopUp = () => {
        setShowPopUp(false);
        setPopUpContent("");
    };

    const handlePreloaderLoad = (loadedAudio) => {
        assignAudio(loadedAudio);
        setAudio(loadedAudio);
        setIsPreloaded(true);
    };

    const renderContent = () => (
        <MathAssessment01Container>
            <AssetShapes onShapeClick={handleShapeClick} options={options} playFinished={isTeacherNotTalking} />
            <ProgressBar progress={progress} total={20} />
            <BoardWrapper>
                <MainBoardWrapper>
                    <MainBoard
                        width={boardDimensions.width}
                        height={boardDimensions.height}
                        answerStatus={answerStatus}
                    >
                        {mainBoardContent}
                    </MainBoard>
                    {showEffect && (
                        <ResultAnimation
                            srcArray={
                                answerStatus === "correct"
                                    ? correctImageSrcArray
                                    : incorrectImageSrcArray
                            }
                            showEffect={showEffect}
                        />
                    )}
                </MainBoardWrapper>
            </BoardWrapper>
            {showPopUp && (
                <PopUp
                    open={showPopUp}
                    styles={{
                        backgroundColor: "rgba(10, 10, 10, 0.8)",
                        color: "white",
                        padding: "200px",
                        borderRadius: "10px",
                    }}
                    isEmbedded={false}
                    fillWidth={false}
                >
                    <PopupWrapper>
                        <PopupContent>{popUpContent}</PopupContent>
                        <PopupButton onClick={closePopUp}>close</PopupButton>
                    </PopupWrapper>
                </PopUp>
            )}
            {showCongratsPopup && (
                <PopUp
                    open={showCongratsPopup}
                    styles={{
                        backgroundColor: "rgba(10, 10, 10, 0.8)",
                        color: "white",
                        padding: "200px",
                        borderRadius: "10px",
                    }}
                    isEmbedded={false}
                    fillWidth={false}
                >
                    <PopupWrapper>
                        <PopupContent>
                            Congratulations! You've completed the assessment!
                        </PopupContent>
                        <PopupButton onClick={() => setShowCongratsPopup(false)}>
                            Close
                        </PopupButton>
                    </PopupWrapper>
                </PopUp>
            )}
        </MathAssessment01Container>
    );

    if (questions.length === 0 || audioFilesToPreload.length === 0) {
        return <div>Loading...</div>;
    }

    const introPlay = () => {
        playArray([`${props.introAudio}`]);
        setIsPlayingIntroAudio(true)
    }

    const fillerSpeakerClickedHandler = (state) => {
        stopPlaying();
        setFillerSpeakerClicked(true);
        playAudio(
            data[state].audio,
            () => {
            setFillerSpeakerTalking(true);
            },
            () => {
            setFillerSpeakerTalking(false);
            }
        );
    };
    const fillerNavClickedHandler = (state) => {
        stopPlaying()
        if (state === "intro") {
            setShowFiller(false);
            questionTask();
        } 
    }

    const stopPlaying = () =>{
        stopAudio()
    }

    return (
        <PreloaderView audios={audioFilesToPreload} onLoad={handlePreloaderLoad}>
            {
                isPreloaded ? (
                    showFiller ? (
                        <Filler
                            speakerClicked={() =>fillerSpeakerClickedHandler('intro')}
                            smallHeader={smallHeader}
                            forwardClicked={() => fillerNavClickedHandler('intro')}
                            payload={{...data.intro, cursor: data.cursor, pointer: data.selectCursor}}
                            talking={fillerSpeakerTalking}
                            started={fillerSpeakerClicked}
                            navBg={data.intro.navForwardGray}
                            navBg1={data.intro.navForwardBg}
                            navBg2={data.intro.navForwardBlueBg}
                            navPointer={data.selectCursor}
                            /* images={images} */
                            
                        />
                    ) : (
                        renderContent()
                    )        
                ) : (<div>Loading...</div>)
            }
            
        </PreloaderView>
    );
};

export default MathAssessment01;