// shapeData.js
import shape1 from '../mathassessment01/assets/asset1.svg';
import shape2 from '../mathassessment01/assets/asset2.svg';
import shape3 from '../mathassessment01/assets/asset3.svg';
import shape4 from '../mathassessment01/assets/asset4.svg';
import shape5 from '../mathassessment01/assets/asset5.svg';
import greenActionButton from '../mathassessment01/assets/asset6.svg';
import purpleActionButton from '../mathassessment01/assets/asset7.svg';
import yellowActionButton from '../mathassessment01/assets/asset8.svg';
import redActionButton from '../mathassessment01/assets/asset9.svg';
import homeButton from '../mathassessment01/assets/asset10.svg';
import leftArrowButton from '../mathassessment01/assets/asset11.svg';
import rightArrowButton from '../mathassessment01/assets/asset12.svg';
import profileBackgroundButton from '../mathassessment01/assets/asset13.svg';
import profileButton from '../mathassessment01/assets/asset14.svg';
import shape15 from '../mathassessment01/assets/asset15.svg';
import shape16 from '../mathassessment01/assets/asset16.svg';
import shape17 from '../mathassessment01/assets/asset17.svg';
import shape18 from '../mathassessment01/assets/asset18.svg';
import shape19 from '../mathassessment01/assets/asset19.svg';
import shape20 from '../mathassessment01/assets/asset20.svg';
import shape21 from '../mathassessment01/assets/asset21.svg';
import shape22 from '../mathassessment01/assets/asset22.svg';
import shape23 from '../mathassessment01/assets/asset23.svg';
import shape24 from '../mathassessment01/assets/asset24.svg';
import shape25 from '../mathassessment01/assets/asset25.svg';

const shapeData = [
  { src: shape1, top: '113px', left: '16.4%', zIndex: 1, width: '885px', height: '450px', opacity: '0.5', objectFit: 'cover', onClick: 'shape1', mobileLandscapeWidth: '70vw', mobileLandscapeHeight: '35vh'},
  { src: shape2, top: '0', bottom: '0', left: '0', zIndex: 0, width: 'auto', height: '100%', opacity: '0.5', mobileLandscapeWidth: 'auto', mobileLandscapeHeight: '100%' },
  { src: shape3, top: '0', bottom: '0', right: '0', zIndex: 0, width: 'auto', height: '100%', opacity: '0.5', mobileLandscapeWidth: 'auto', mobileLandscapeHeight: '100%' },
  { src: shape4, objectFit: 'cover', width: '100%', height: '100%', mobileLandscapeWidth: '100%', mobileLandscapeHeight: '100%'  },
  { src: greenActionButton, mobileWidth: '80px', mobileHeight: '80px', mobileTop: '56%', mobileLeft: '22%', iPadPortraitTop:'56%', iPadLandscapeTop:'60%', iPadPortraitLeft:'25%', iPadLandscapeLeft: '20%', iPadLandscapeZIndex: 4, mobileLandscapeTop: '72%', iPadPortraitZIndex: 4, mobile2LandscapeTop: '75%', mobileLandscapeLeft: '17%', mobile2LandscapeLeft: '17%', mobileLandscapeZIndex: 4, mobile2LandscapeZIndex: 4, top: '70%', left: '30%', ultraWideTop:'75%', ultraWideLeft: '30%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'300px', ultraWideHeight: '300px', onClick: 'greenActionButton', text: 'default', actionType:'answer', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh', mobile2LandscapeHeight: '100px', mobile2LandscapeWidth: '100px' },
  { src: purpleActionButton, mobileWidth: '80px', mobileHeight: '80px', mobileTop: '56%', mobileLeft: '60%', iPadPortraitTop:'56%', iPadLandscapeTop:'60%', iPadPortraitLeft:'55%', iPadLandscapeLeft: '37%', iPadLandscapeZIndex: 4, mobileLandscapeTop: '72%', iPadPortraitZIndex: 4, mobile2LandscapeTop: '75%', mobileLandscapeLeft: '34%', mobile2LandscapeLeft: '34%', mobileLandscapeZIndex: 4, mobile2LandscapeZIndex: 4, top: '70%', left: '42%', ultraWideTop:'75%', ultraWideLeft: '42%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'300px', ultraWideHeight: '300px', onClick: 'purpleActionButton', text: 'default', actionType:'answer', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh', mobile2LandscapeHeight: '100px', mobile2LandscapeWidth: '100px' },
  { src: yellowActionButton, mobileWidth: '80px', mobileHeight: '80px', mobileTop: '66%', mobileLeft: '22%', iPadPortraitTop:'66%', iPadLandscapeTop:'60%', iPadPortraitLeft:'25%', iPadLandscapeLeft: '54%', iPadLandscapeZIndex: 4, mobileLandscapeTop: '72%', iPadPortraitZIndex: 4, mobile2LandscapeTop: '75%', mobileLandscapeLeft: '51%', mobile2LandscapeLeft: '51%', mobileLandscapeZIndex: 4, mobile2LandscapeZIndex: 4, top: '70%', left: '54%', ultraWideTop:'75%', ultraWideLeft: '54%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'300px', ultraWideHeight: '300px', onClick: 'yellowActionButton', text: 'default', actionType:'answer', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh', mobile2LandscapeHeight: '100px', mobile2LandscapeWidth: '100px' },
  { src: redActionButton, mobileWidth: '80px', mobileHeight: '80px', mobileTop: '66%', mobileLeft: '60%', iPadPortraitTop:'66%', iPadLandscapeTop:'60%', iPadPortraitLeft:'55%', iPadLandscapeLeft: '71%', iPadLandscapeZIndex: 4, iPadPortraitZIndex: 4, mobileLandscapeTop: '72%', mobile2LandscapeTop: '75%', mobileLandscapeLeft: '68%', mobile2LandscapeLeft: '68%', mobileLandscapeZIndex: 4, mobile2LandscapeZIndex: 4, top: '70%', left: '66%', ultraWideTop:'75%', ultraWideLeft: '66%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'300px', ultraWideHeight: '300px', onClick: 'redActionButton', text: 'default', actionType:'answer', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh', mobile2LandscapeHeight: '100px', mobile2LandscapeWidth: '100px' },
  { src: homeButton, mobileWidth: '10vw', mobileHeight: '10vh', mobileTop: '87%', iPadLandscapeTop: '87%', mobileLeft: '45%', mobileLandscapeRight: '5%', mobileLandscapeTop: '85%', mobileLandscapeWidth: '10vw', mobileLandscapeHeight: '10vh', mobile2LandscapeTop: '78%', mobile2LandscapeHeight: '9vw', mobile2LandscapeWidth: '9vh', mobile2LandscapeRight: '3%', bottom: '13%', right: '12%', zIndex: 2, width: '60px', height: '60px', ultraWideWidth:'200px', ultraWideHeight: '200px', onClick: 'homeButton'  },
  { src: leftArrowButton, mobileWidth: '30px', mobileHeight: '30px', mobileTop: '79%', mobileLeft: '35%', mobileLandscapeTop: '27%', mobile2LandscapeTop: '28%', iPadPortraitLeft:'1%', iPadLandscapeLeft:'5%', mobile2LandscapeLeft: '4%', mobile2LandscapeWidth: '120px', mobile2LandscapeHeight: '120px', mobileLandscapeLeft: '4%', mobileLandscapeWidth: '100px', mobileLandscapeHeight: '100px', top: '32%', left: '20%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'400px', ultraWideHeight: '400px', onClick: 'leftArrowButton', actionType: 'navigation' },
  { src: rightArrowButton, mobileWidth: '30px', mobileHeight: '30px', mobileTop: '79%', mobileLeft: '60%', mobile2LandscapeTop: '28%', mobile2LandscapeRight: '5%', iPadPortraitRight:'1%', iPadLandscapeRight:'5%', mobile2LandscapeWidth: '120px', mobile2LandscapeHeight: '120px', mobileLandscapeTop: '27%', mobileLandscapeLeft: '80%', mobileLandscapeWidth: '100px', mobileLandscapeHeight: '100px', top: '32%', right: '13%', zIndex: 2, width: '150px', height: '150px', ultraWideWidth:'400px', ultraWideHeight: '400px', onClick: 'rightArrowButton', actionType: 'navigation' },
  //{ src: profileBackgroundButton, mobileWidth: '12vw', mobileHeight: '12vh', mobileTop: '1%', mobileLeft: '42%', top: '5%', left: '20%', zIndex: 1, width: '105px', height: '105px' },
  //{ src: profileButton, mobileWidth: '10vw', mobileHeight: '10vh', mobileTop: '2%', mobileLeft: '43%', top: '6%', left: '20.9%', zIndex: 1, width: '85px', height: '85px', onClick: 'profileButton' },
  { src: shape15, opacity: '0.5', mobileWidth: '15vw', mobileHeight: '15vh', mobileTop: '51%', mobileLeft: '90%', top: '35%', left: '10%', zIndex: 1, width: '80px', height: '80px', ultraWideWidth:'266.67px', ultraWideHeight: '266.67px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape16, opacity: '0.5', mobileWidth: '12vw', mobileHeight: '', mobileTop: '71%', mobileLeft: '1%', top: '48%', left: '5%', zIndex: 1, width: '90px', height: '90px', ultraWideWidth:'300px', ultraWideHeight: '300px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape17, opacity: '0.5', mobileWidth: '12vw', mobileHeight: '', mobileTop: '81%', mobileLeft: '5%', top: '60%', left: '10%', zIndex: 1, width: '75px', height: '75px', ultraWideWidth:'250px', ultraWideHeight: '250px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape18, opacity: '0.5', mobileWidth: '12vw', mobileHeight: '', mobileTop: '71%', mobileLeft: '1%', top: '65%', left: '16%', zIndex: 2, width: '140px', height: '140px', ultraWideWidth:'466.67px', ultraWideHeight: '466.67px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape19, opacity: '0.5', mobileWidth: '12vw', mobileHeight: '', mobileTop: '71%', mobileLeft: '85%', top: '15%', left: '9%', zIndex: 1, width: '160px', height: '160px', ultraWideWidth:'533.33px', ultraWideHeight: '533.33px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape20, opacity: '0.5', mobileWidth: '5vw', mobileHeight: '', mobileTop: '71%', mobileLeft: '85%', top: '65%', left: '90%', zIndex: 2, width: '50px', height: '50px', ultraWideWidth:'166.67px', ultraWideHeight: '166.67px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape21, opacity: '0.5', mobileWidth: '5vw', mobileHeight: '', mobileTop: '2%', mobileLeft: '21%', top: '18%', right: '8%', zIndex: 1, width: '75px', height: '75px', ultraWideWidth:'200px', ultraWideHeight: '200px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape22, opacity: '0.5', mobileWidth: '20vw', mobileHeight: '', mobileTop: '1%', mobileLeft: '60%', top: '30%', left: '87%', zIndex: 1, width: '150px', height: '150px', ultraWideWidth:'250px', ultraWideHeight: '250px', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape23, opacity: '0.5', mobileWidth: '11vw', mobileHeight: '11vh', mobileTop: '0%', mobileLeft: '75%', top: '1.5%', left: '78%', zIndex: 1, width: '100px', height: '100px', ultraWideWidth:'333.33px', ultraWideHeight: '333.33px', onClick: 'shape23', mobileLandscapeWidth: '15vw', mobileLandscapeHeight: '15vh' },
  { src: shape24, bottom: '0', left: '0', right: '0', objectFit: 'cover', zIndex: 1, width: '100%', height: 'auto', opacity: '0.5' },
  /* { src: shape25, top: '10%', left: '20', zIndex: 2, width: '100%', height: '100%' }, */
];

export default shapeData;
